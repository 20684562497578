import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';
import TextArea from '../../Form/TextArea';

import { requireField, errorTypes } from '../../../utils/formValidations';

function OrderUploadImageForm(props: any) {
  // const [step, setStep] = useState(0);
  const { onSubmit, title } = props;
  const { register, handleSubmit, formState: { errors } } = useForm();


  return (
    <div className="row">
      <div className="mx-auto d-block col">
        <div className="card border shadow-none card-body">
          <h5 className="card-title mg-b-20">{title}</h5>
          <form className="form mx-auto d-block col" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <Input 
                  type={"file"} 
                  name={"file"} 
                  validation={{ 
                    required: requireField,
                  }} 
                  label={"Archivo De Evidencia: "} 
                  errorMessages={[{ type: errorTypes.required, message: 'Adjunte el archivo' }, { type: errorTypes.validate, message: 'Adjunte archivo ',  }]}
                  register={register} 
                  errors={errors}
                />
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-block btn">Subir Archivo</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrderUploadImageForm;

import React from 'react';

import Header from '../Dashboard/Header';

function Placeholder () {
    return (
        <div className="m-5">
            <Header title=''/>
            <div className="row row-sm">
                <div className='col col-md-12 col-lg-12'>
                    <div className="card custom-card">
                        <p className="card-text placeholder-glow">
                            <span className="placeholder col-7"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-4"></span>
                            <span className="placeholder col-6"></span>
                            <span className="placeholder col-8"></span>
                            <span className="placeholder col-8"></span>
                            <span className="placeholder col-8"></span>
                            <span className="placeholder col-8"></span>
                            <span className="placeholder col-8"></span>
                            <span className="placeholder col-8"></span>
                            <span className="placeholder col-8"></span>
                            <span className="placeholder col-8"></span>
                            <span className="placeholder col-12"></span>
                            <span className="placeholder col-12"></span>
                            <span className="placeholder col-8"></span>
                            <span className="placeholder col-10"></span>
                            <span className="placeholder col-10"></span>
                            <span className="placeholder col-10"></span>
                            <span className="placeholder col-10"></span>
                            <span className="placeholder col-10"></span>
                            <span className="placeholder col-10"></span>
                            <span className="placeholder col-10"></span>
                            <span className="placeholder col-10"></span>
                            <span className="placeholder col-10"></span>
                            <span className="placeholder col-10"></span>
                            <span className="placeholder col-10"></span>
                            <span className="placeholder col-10"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Placeholder

import React, { useEffect }from 'react';
import { useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { selectUsers } from '../../../../app/store/slice/user';

import Profile from '../../../../components/Profile';

function UsersDetail (props:any) {
    
    const { user } = useAppSelector(selectUsers);
    
    return (
        <Profile user={user}/> 
    )
}

export default UsersDetail;

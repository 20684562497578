import { User } from "../models/user";

export const roleValidation = (userGroups: Array<string>, user: User ) => {
    if (userGroups.includes(user?.user_group)) {
        return true;
    }
    return false;
}

export const roleDeptValidation = (userGroups: Array<string>, jobs: Array<string>, user: User ) => {
    if (userGroups.includes(user?.user_group) &&  jobs.includes(user?.job)) {
        return true;
    }
    return false;
}
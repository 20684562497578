import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState} from '../..';
import { OperatorsState } from '../../../../models/operators';
import { fetchOperators, fetchOperator, createOperator, updateOperator } from './asyncTasks';

const initialState: OperatorsState = {
  operators: [],
  operator: undefined,
  status: 'idle',
  message: '',
};

export const operatorsSlice = createSlice({
  name: 'operators',
  initialState,
  reducers: {
    getOperators: function(state, action: PayloadAction<number>) {},
    changeStatusToIdle: function (state) {
      state.status = "idle";
      state.message = "";
    },
  },
  extraReducers: function (builder) {
    builder
      .addCase(fetchOperators.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(fetchOperators.fulfilled, function (state, action){
        state.operators = action.payload.data.items;
        state.status = "fulfilled";
      })
      .addCase(fetchOperators.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Fetch operator
      .addCase(fetchOperator.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(fetchOperator.fulfilled, function (state, action){
        state.operator = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(fetchOperator.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Create operator
      .addCase(createOperator.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(createOperator.fulfilled, function (state, action){
        state.operator = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(createOperator.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Update operator
      .addCase(updateOperator.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(updateOperator.fulfilled, function (state, action){
        state.operator = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(updateOperator.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
  },
});

export const { getOperators, changeStatusToIdle } = operatorsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export function selectOperators (state: RootState) { return state.operators };

export default operatorsSlice.reducer;

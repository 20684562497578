import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks/redux';
import { changePassword } from '../../app/store/slice/auth/asyncTasks';
import { selectAuth } from '../../app/store/slice/auth';

import ChangePasswordComponent from '../../components/Auth/ChangePassword';

function ChangePassword () {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(selectAuth);

    function onSubmit (formValues: any) {
        const passwordChallengeValues: Object = {
            email: user.email,
            name: user.name,
            password: formValues.password,
            newPassword: formValues.newPassword,
        }
        dispatch(changePassword(passwordChallengeValues));
        navigate(`/dashboard/profile`);
    };

    return (
        <ChangePasswordComponent onSubmit={onSubmit}  />
    );
};

export default ChangePassword;


import { createAppAsyncThunk } from '../../../hooks/redux';
import cutDesignApi from '../../../../apis/cutDesignApi';
import { User } from '../../../../models/auth';

import { signOut } from '../auth/asyncTasks';
import axios from 'axios';

import { UNAUTHORIZED_ERROR_TYPE, UNAUTHORIZED_ERROR_MESSAGE } from '../../../../utils/types';

export const fetchOrders = createAppAsyncThunk('orders/fetchOrders', async function (_, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;

        const res = await cutDesignApi.get(`/orders`, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
      
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const fetchOrder = createAppAsyncThunk('orders/fetchOrder', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;
        const { client_id, order_id } = values;
        
        const res = await cutDesignApi.get(`/clients/${client_id}/orders/${order_id}`, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
       
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const updateOrder = createAppAsyncThunk('orders/updateOrder', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;
        const { order_id, client_id, order } = values;
        
        const res = await cutDesignApi.put(`/clients/${client_id}/orders/${order_id}`, order, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
       
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };
        
        return rejectWithValue(err.response.data);
    }
});

async function getPresignedUrl ({ user, extension, path, endpointType }: any) {
    try {
       const value = !path ? { file_extension: extension } : { file_path: path }
        const res =  await cutDesignApi.post(`/clients/${user.id}/presigned_url/${endpointType}`, value, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
        return err;
    }
};

async function uploadToS3 ({ presignedResult, file }: any) {
    try {

        const res = await axios.put(presignedResult.data.presigned_url, file);

        return res;
    } catch (err: any) {
        return err;
    }
};


export const uploadFileAPI = createAppAsyncThunk('orders/uploadFileAPI', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {
    
        const user: User = getState().auth.user;
        const { client_id, order_id, formValues, uploadType } = values;
        const { reception_file_paths } = values || '';
        const { delivery_file_paths } = values || '';

        const file: File = formValues.file[0];
        const extension: string = file.name.slice((Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1);
        const endpointType: string = "put";

        const presignedResult = await getPresignedUrl({ user: { id: client_id, accessToken: user.accessToken }, extension, endpointType });
        await uploadToS3({ presignedResult, file });

        let body = {}
        if ( uploadType === 'reception_file_paths') {
            body = { [uploadType]: [...reception_file_paths, presignedResult.data.file_path]};
        } else if (uploadType === 'delivery_file_paths'){
            body = { [uploadType]: [...delivery_file_paths, presignedResult.data.file_path]};
        }
        
        const res = await cutDesignApi.put(`/clients/${client_id}/orders/${order_id}`, body, { 
            headers: {
                Authorization: user.accessToken,
            }
         });

         return res;
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const getFiles = createAppAsyncThunk('orders/getFiles', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {
        const user: User = getState().auth.user;
        const { client_id, downloadType } = values;
        const endpointType: string = "get";
        
        // handleDownload singleFile
        if(downloadType === 'singleFile') {
            let { path } = values;
            let { data } = await getPresignedUrl({ user: { id: client_id, accessToken: user.accessToken }, path, endpointType });
            let presignedUrl = data.presigned_url

            return { presignedUrl, downloadType };
        }
        
        // Calling getFile directly for array paths.
        let { paths } = values;

        let presignedUrls: Array<string> = [];

        presignedUrls = await Promise.all(paths.map(async (path: string) => {
            let res = await getPresignedUrl({ user: { id: client_id, accessToken: user.accessToken }, path, endpointType });
            return res.data.presigned_url;
        }));

        return { presignedUrls, downloadType };        
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };
        
        return rejectWithValue(err.response.data);
    }
});

async function deleteFromS3({ presignedResult, path }: any) {
    try {

        const res = await axios.delete(presignedResult.data.presigned_url, path);

        return res;
    } catch (err: any) {
        return err;
    }
};

export const deleteFile = createAppAsyncThunk('orders/deleteFile', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {
        
        const user: User = getState().auth.user;
        const { client_id, path, deleteType, order_id } = values;
        let { reception_file_paths } = values || '';
        let { delivery_file_paths } = values || '';

        const endpointType: string = "delete";
       
        const presignedResult = await getPresignedUrl({ user: { id: client_id, accessToken: user.accessToken }, path, endpointType });
        console.log('presignedResult', presignedResult);
        await deleteFromS3({ presignedResult, path });

        let body = {}

        if (deleteType === 'reception_file_paths') {
            reception_file_paths = reception_file_paths.filter( (file_path: any) => file_path !== presignedResult.data.file_path);
            body = { 
                [deleteType]: reception_file_paths
            };
        } else if (deleteType === 'delivery_file_paths') {
            delivery_file_paths = delivery_file_paths.filter( (file_path: any) => file_path !== presignedResult.data.file_path);
            body = { [deleteType]: delivery_file_paths };
        }
        
        const res = await cutDesignApi.put(`/clients/${client_id}/orders/${order_id}`, body, { 
            headers: {
                Authorization: user.accessToken,
            }
         });

         console.log('DATA DeleteFie', res.data);
         return res;
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };
        
        return rejectWithValue(err.response.data);
    }
});
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppThunkDispatch } from '../../../../app/hooks/redux';
import { createClient } from '../../../../app/store/slice/clients/asyncTasks';


import ClientCreateComponent from '../../../../components/Clients/ClientCreate';

function ClientCreate () {

    const navigate = useNavigate();
    const dispatch = useAppThunkDispatch();
    
    function onSubmit (formValues: any) {
        dispatch(createClient(formValues));
        navigate("/dashboard/clients");
    }; 

    return (
        <ClientCreateComponent onSubmit={onSubmit} /> 
    )
};

export default ClientCreate;

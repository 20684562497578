import { createAppAsyncThunk } from '../../../hooks/redux';
import cutDesignApi from '../../../../apis/cutDesignApi';
import { User } from "../../../../models/auth";

import { signOut } from '../auth/asyncTasks';
import { UNAUTHORIZED_ERROR_MESSAGE, UNAUTHORIZED_ERROR_TYPE } from '../../../../utils/types';

export const fetchQuotations = createAppAsyncThunk('quotations/fetchQuotations', async function (id: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;

        const res = await cutDesignApi.get(`/orders/${id}/quotations`, {
            headers: {
                Authorization: user.accessToken,
            }
        });
        return res;
    } catch (err: any) {

        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };

        return rejectWithValue(err.response.data);
    }
});

export const fetchQuotationsByDate = createAppAsyncThunk('quotations/fetchQuotationsByDate', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user
        const { dateObject } = values;

        const startDate = dateObject.viewMode === "week" ? dateObject.startOfWeekDate.toISOString() : dateObject.startOfDayDate.toISOString();
        const endDate = dateObject.viewMode === "week" ? dateObject.endOfWeekDate.toISOString() : dateObject.endOfDayDate.toISOString();

        const res = await cutDesignApi.get(`/quotations`, {
            headers: {
                Authorization: user.accessToken,
            },
            params: {
                start_date: startDate,
                end_date: endDate,
            }
        });

        return res;
    } catch (err: any) {

        if (err.response.status === 403) {
            if (err.response.data.error_code === "EXPIRED_TOKEN") {
                dispatch(signOut());
            };
        };

        return rejectWithValue(err.response.data);
    }
});

export const createQuotations = createAppAsyncThunk('quotations/createQuotations', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;
        const { id, quotations } = values;

        const res = await cutDesignApi.post(`/orders/${id}/quotations/batch`, quotations, {
            headers: {
                Authorization: user.accessToken,
            }
        });
        return res;
    } catch (err: any) {

        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };

        return rejectWithValue(err.response.data);
    }
});

export const updateQuotation = createAppAsyncThunk('quotations/updateQuotation', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;
        const { order_id, quotation_id, quotation} = values;
        
        const res = await cutDesignApi.put(`/orders/${order_id}/quotations/${quotation_id}`, quotation, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
       
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };
        
        return rejectWithValue(err.response.data);
    }
});

import React, { Fragment, useEffect } from 'react';
import { useAppDispatch, useAppThunkDispatch, useAppSelector } from '../../../app/hooks/redux';
import { setSidebarClassName } from '../../../app/store/slice/dashboard';
import { selectUsers } from '../../../app/store/slice/user';
import { fetchUser } from '../../../app/store/slice/user/asyncTasks';
import { signOut } from '../../../app/store/slice/auth/asyncTasks';
import { selectAuth } from '../../../app/store/slice/auth';
import { fetchNotifications, updateNotificationsBatch } from '../../../app/store/slice/notifications/asyncTasks';
import { selectNotifications } from '../../../app/store/slice/notifications';

import NavigationComponent from '../../../components/Dashboard/Navigation';

function Navigation () {

    const dispatch = useAppDispatch();
    const dispatchThunk = useAppThunkDispatch();
    const { user } = useAppSelector(selectUsers);
    const auth = useAppSelector(selectAuth);
    const { notifications, status } = useAppSelector(selectNotifications);  

    useEffect(function () {

        dispatchThunk(fetchUser(auth.user.id));

        if (status === "idle") {
            dispatchThunk(fetchNotifications());
        }

        const interval = setInterval(function () {
            dispatchThunk(fetchNotifications());
          }, 300000);

        return function () {
            clearInterval(interval);
        };
    }, [dispatchThunk]);

    function onHideShow(isToggled: boolean) {
        dispatch(setSidebarClassName({ value: isToggled ? "sidenav-toggled" : "" }));
    };

    function markNotificationsSeen () {

        const notificationsUnseen = notifications.filter(function(notification: any) { return !notification.seen });

        const notificationsSeen = notificationsUnseen.map(function (notification: any) {
            notification = { ...notification, seen: true };

            return notification;
        });

        if (notificationsSeen.length > 0) {
            dispatchThunk(updateNotificationsBatch(notificationsSeen));
        };
    };

    function logout () {
        dispatch(signOut());
    };

    return (
        <Fragment>
            {
                !user ?
                    null
                :
                    <NavigationComponent
                        onHideShow={onHideShow}
                        markNotificationsSeen={markNotificationsSeen}
                        logout={logout}
                        user={user}
                        notifications={notifications}
                    />
            }
        </Fragment>
    );
};

export default Navigation;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks/redux';
import { selectClients } from '../../../../app/store/slice/clients';
import { useAppThunkDispatch } from '../../../../app/hooks/redux';
import { updateClient } from '../../../../app/store/slice/clients/asyncTasks';
import { selectAuth } from '../../../../app/store/slice/auth';

import ClientUpdateComponent from '../../../../components/Clients/ClientUpdate';

function ClientUpdate () {

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useAppThunkDispatch();
    const { client } = useAppSelector(selectClients);
    const { user } = useAppSelector(selectAuth);
    
    function onSubmit (formValues: any) {
        dispatch(updateClient(formValues));
        navigate(`/dashboard/clients/${params.id}/detail`);
    }; 

    return (
            <ClientUpdateComponent onSubmit={onSubmit} client={client} user={user}/>
    )
};

export default ClientUpdate;

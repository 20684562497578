import React from 'react';
import { Modal } from 'react-bootstrap';

function Success (props: any) {

    const { title, content, status, onDismiss } = props;

    return (
        <Modal show={status === "fulfilled" ? true : false} aria-labelledby="contained-modal-title-vcenter" centered>
        <div className='modal-header'>
          <button className="btn btn-close" onClick={function () { onDismiss() }}>
            x
          </button>
        </div>
        <div className='modal-body'>
          <div className="tx-center ">
            <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-success lh-1 mg-t-20 d-inline-block"></i>{" "}
            <h4 className="tx-success tx-semibold mg-b-20">{title}</h4>{" "}
            <p className="mg-b-20 mg-x-20">
              {content}
            </p>
            <button
              className="btn ripple btn-success pd-x-25"
              onClick={function () { onDismiss() }}
              type="button"
            >
              Continuar
            </button>{" "}
          </div>
        </div>
      </Modal>
    );
};

export default Success;

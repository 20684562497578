import { createAppAsyncThunk } from "../../../hooks/redux";
import cutDesignApi from '../../../../apis/cutDesignApi';
import { User } from '../../../../models/auth';

import { signOut } from "../auth/asyncTasks";

import { UNAUTHORIZED_ERROR_TYPE, UNAUTHORIZED_ERROR_MESSAGE } from '../../../../utils/types';

export const fetchClients = createAppAsyncThunk('clients/fetchClients', async function (_, { rejectWithValue, getState, dispatch }) {
    try {
        
        const user: User = getState().auth.user;

        const res = await cutDesignApi.get(`/clients`, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
      
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const fetchClient = createAppAsyncThunk('clients/fetchClient', async function (id: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;
        
        const res = await cutDesignApi.get(`/clients/${id}`, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
       
    } catch (err: any) {
      
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const createClient = createAppAsyncThunk('clients/createClient', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {
        const user: User = getState().auth.user;
        
        const res = await cutDesignApi.post(`/clients`, values, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const updateClient = createAppAsyncThunk('clients/updateClient', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {
        const user: User = getState().auth.user;
        const res = await cutDesignApi.put(`/clients/${values.id}`, values, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {

        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };
        
        return rejectWithValue(err.response.data);
    }
});

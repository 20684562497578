import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks/redux';
import { selectAuth } from '../../app/store/slice/auth';
import { signIn } from '../../app/store/slice/auth/asyncTasks';

import LoginComponent from '../../components/Auth/Login';
import { SignInPayload } from '../../models/auth';

function Login () {

    const dispatch = useAppDispatch();
    const auth = useAppSelector(selectAuth);

    function onSubmit (formValues: SignInPayload) {
        dispatch(signIn(formValues));
    };
    return (
        <LoginComponent onSubmit={onSubmit} />
    );
};

export default Login;

import React from 'react';

import { Dropdown } from 'react-bootstrap';

function NotificationItem (props: any) {

    const { notification } = props;

    return (
        <Dropdown.Item className="d-flex justify-content-start p-1 border-bottom">
            <div className="notifyimg bg-purple">
                <i className="fas fa-exclamation text-white"></i>
            </div>
            <div className="ms-3">
                <h5 className="notification-label mb-1">
                    { notification.subject }
                </h5>
                <div className="notification-subtext">
                    { notification.creation_date }
                </div>
            </div>
        </Dropdown.Item>
    );
};

export default NotificationItem;

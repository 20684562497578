import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

function CardItem(props: any) {

    const { handleDownload, file_path, image_url, type, handleDelete } = props;

    return (
        <Card className="custom-card w-25">
            <Card.Body>
                {/* <div>
                    <h6 className="main-content-label mb-1 text-center">Image-attachment</h6>
                    <p className="text-muted card-sub-title"></p>
                </div> */}
                <div className="h-100  attached-file-grid6">
                    <div className="pro-img-box attached-file-image">
                        <img
                            className=" pic-1 pos-relative br-5 w-100"
                            src={image_url}
                            alt="attached-file"
                        />
                        {/* <span className="image-pic text-center" >123image.jpg</span> */}
                        <ul className="icons">
                            <li className="me-1">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip > Download</Tooltip>
                                    }
                                >
                                    <i className="fe fe-download"
                                        onClick={
                                            function () { handleDownload(file_path, 'singleFile') }
                                        }
                                    ></i>
                                </OverlayTrigger>
                            </li>
                            <li className="me-1">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip className="btn">Delete</Tooltip>}
                                >
                                    <i className="fe fe-trash"
                                    onClick={
                                        function () { handleDelete(file_path, type) }
                                    }></i>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default CardItem;
import React from 'react';

function Select (props: any) {

    const { name, options, validation, label, errorMessages, register, values, errors } = props;

    return (
        <div className="form-group dropdown">
            <label className="form-label fs-6">{label}</label>
            <select {...register(name, validation)} className="form-control">
            <option hidden value="">{`${label}`}</option>
                {options.map(function(option: any, i: number) {
                    return <option key={`${option}`} value={`${option}`}>{`${option}`}</option>
                })}
            </select>
            {errorMessages.map(function(errorMessage: any) {
                return errors[name] && errors[name].type === errorMessage.type && <p key={errorMessage} className="text text-danger">{errorMessage.message}</p>
            })}
        </div>
    );
};

export default Select;
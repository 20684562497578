import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedDashboardRoute (props: any) {

    const { user, children } = props;

    if (user.isSigned === false) {
        return <Navigate to={"/"} replace />;
    }

    return children;
};

export default ProtectedDashboardRoute;

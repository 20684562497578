import React, { useEffect, useState } from 'react';
import { startOfWeek, endOfWeek, add, startOfDay, endOfDay, sub } from 'date-fns';

import { useAppThunkDispatch, useAppSelector } from '../../../app/hooks/redux';
import { fetchQuotationsByDate } from '../../../app/store/slice/quotations/asyncTasks';
import GanttComponent from '../../../components/Gantt';

import { selectQuotations } from "../../../app/store/slice/quotations";
import Placeholder from '../../../components/Placeholder';

function GanttChart(props: any) {
    const isOnlyGanttChart = true;

    const dispatch = useAppThunkDispatch();
    const { quotations, status } = useAppSelector(selectQuotations);

    const [dateObject, setDateObject] = useState(
        {
            viewMode: "week",
            startOfDayDate: startOfDay(new Date()),
            endOfDayDate: endOfDay(new Date()),
            startOfWeekDate: startOfDay(new Date()),
            endOfWeekDate: endOfWeek(new Date()),
        }
    );

    useEffect(function () {
        const params = {
            dateObject: dateObject
        }
        dispatch(fetchQuotationsByDate(params));
    }, [dispatch, dateObject]);

    const prevPage = () => {
        setDateObject(
            {
                viewMode: dateObject.viewMode,
                startOfDayDate: startOfDay(sub(dateObject.startOfDayDate, { days: 1 })),
                endOfDayDate: endOfDay(sub(dateObject.endOfDayDate, { days: 1 })),
                startOfWeekDate: startOfWeek(sub(dateObject.startOfWeekDate, { weeks: 1 })),
                endOfWeekDate: endOfWeek(sub(dateObject.endOfWeekDate, { weeks: 1 }))
            }
        )
    }

    const nextPage = () => {
        setDateObject(
            {
                viewMode: dateObject.viewMode,
                startOfDayDate: startOfDay(add(dateObject.startOfDayDate, { days: 1 })),
                endOfDayDate: endOfDay(add(dateObject.endOfDayDate, { days: 1 })),
                startOfWeekDate: startOfWeek(add(dateObject.startOfWeekDate, { weeks: 1 })),
                endOfWeekDate: endOfWeek(add(dateObject.endOfWeekDate, { weeks: 1 }))
            }
        )
    }

    const setRangeToThisDay = () => {
        setDateObject(
            {
                viewMode: "day",
                startOfDayDate: startOfDay(new Date()),
                endOfDayDate: endOfDay(new Date()),
                startOfWeekDate: startOfDay(new Date()),
                endOfWeekDate: endOfWeek(new Date()),
            }
        )

    }

    const setRangeToThisWeek = () => {
        setDateObject(
            {
                viewMode: "week",
                startOfDayDate: startOfDay(new Date()),
                endOfDayDate: endOfDay(new Date()),
                startOfWeekDate: startOfDay(new Date()),
                endOfWeekDate: endOfWeek(new Date()),
            }
        )
    }

    return (
        status === "idle" || status === "loading" ?
            <Placeholder />
            :
            <GanttComponent
                isOnlyGanttChart={isOnlyGanttChart}
                quotations={quotations}
                prevPage={prevPage}
                nextPage={nextPage}
                setRangeToThisDay={setRangeToThisDay}
                setRangeToThisWeek={setRangeToThisWeek}
            />
    )
}

export default GanttChart;

import React, { useState, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppThunkDispatch } from '../../../../app/hooks/redux';
import { createQuotations } from '../../../../app/store/slice/quotations/asyncTasks';
import { updateOrder } from '../../../../app/store/slice/orders/asyncTasks';

import QuotationCreateComponent from '../../../../components/Quotations/QuotationCreate';
import Gantt from '../../../../components/Gantt';

function QuotationCreate () {

    const navigate = useNavigate();
    const params = useParams()
    const dispatch = useAppThunkDispatch();
    const [quotations, setQuotations] = useState<any>([]);
    const isOnlyGanttChart = false;

    // - Quotation <Type><Date><Epoch>
    //  CNCMA300424093

    function customQuotationId(quotationType: string){

        const firstLetters = quotationType.slice(0, 2).toUpperCase();
        const date = new Date();
        const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: '2-digit' });
        const formattedDate = formatter.format(date).replaceAll('/','');

        const now = Date.now().toString();
        const lastDigits = now.slice(-4);

        const customQuotationId = ''.concat( firstLetters, formattedDate, lastDigits);

        return customQuotationId;  
    }

    function addQuotation (formValues: any) {

        const  extraValues = [];
        formValues.quotation_custom_id = customQuotationId(formValues.quotation_type)
        formValues.machine_duration_minutes =  parseInt(formValues.machine_duration_minutes, 10);
        formValues.machine_scheduled_date = new Date(formValues.machine_scheduled_date).toISOString();
        formValues.due_date = new Date(formValues.due_date).toISOString();
        formValues.price = Number(formValues.price);

        if(formValues.extra_material_reception) {
            extraValues.push({
                description: "extra_material_reception",
                price: Number(formValues.extra_material_reception_price),
                user_input: "",
                is_selected: false,
            });
        }

        if(formValues.extra_delivery) {
            extraValues.push({
                description: "extra_delivery",
                price: Number(formValues.extra_delivery_price),
                user_input: "",
                is_selected: false,
            });
        }

        delete formValues.extra_material_reception;
        delete formValues.extra_material_reception_price;
        delete formValues.extra_delivery;
        delete formValues.extra_delivery_price;

        if (extraValues.length > 0) {
            formValues.extras = extraValues;
        }

        setQuotations([ ...quotations, formValues]);
    };

    function onSubmit () {
        dispatch(createQuotations({ id: params.id, quotations: quotations }));
        dispatch(updateOrder({ 
            order_id: params.id,
            client_id: params.clientId,
            order: {
                status: "awaiting_payment",
            }
         } ));
        navigate(`/dashboard/orders/${params.id}/${params.clientId}/detail`);
    };

    return (
        <Fragment>
            <QuotationCreateComponent addQuotation={addQuotation} quotations={quotations} onSubmit={onSubmit} />
            <Gantt isOnlyGanttChart={isOnlyGanttChart} quotations={quotations} />
        </Fragment>
    
    );
};

export default QuotationCreate;

import React, { Fragment } from 'react';

function Header (props: any) {

    const { title } = props;

    return (
        <Fragment>
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">{title}</span>
                    <div className="justify-content-center mt-2">
                        <ol className='breadcrumb'>
                            <li className="breadcrumb-item tx-15"><a href="#">
                                Inicio
                                </a>
                            </li>
                            <li className="breadcrumb-item tx-15" aria-current="page">
                                Atrás
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Header;

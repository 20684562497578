import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks/redux';
import { selectAuth } from '../../app/store/slice/auth';
import { selectDashboard } from '../../app/store/slice/dashboard';

import Orders from './Orders';
import Clients from './Clients';
import Operators from './Operators';
import Profile from './Profile';
import GanttChart from '../../containers/Dashboard/Gantt';
import Navigation from '../../containers/Dashboard/Navigation';
import Sidebar from '../../containers/Dashboard/Sidebar';

function Dashboard (props: any) {

    const sidebarClassName = useAppSelector(selectDashboard);
    const auth = useAppSelector(selectAuth);

    return (
        <div className={`ltr main-body app sidebar-mini ${sidebarClassName}`}>
            <div className="horizontalMenucontainer">
                <div className="page">
                    <div className="open">
                        <Navigation />
                        <Sidebar />
                    </div>
                    <div className="main-content app-content" >
                        <div className="side-app">
                            <div className="main-container container-fluid">
                                 <Routes>
                                    <Route path="/orders/*" element={<Orders />} /> 
                                    <Route path="/clients/*" element={<Clients />} /> 
                                    <Route path="/profile/*" element={<Profile />} /> 
                                    {
                                        auth.user.userGroup === "Operator" ?
                                            null
                                        :
                                        <Route path="/operators/*" element={<Operators />} /> 
                                    } 
                                    <Route path="/gantt-chart" element={<GanttChart />} />
                                </Routes>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

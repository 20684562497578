import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState} from '../..';
import { OrdersState } from '../../../../models/orders';
import { fetchOrders, fetchOrder, updateOrder, uploadFileAPI, getFiles, deleteFile } from './asyncTasks';

const initialState: OrdersState = {
  orders: [],
  reception_image_urls: [],
  delivery_image_urls: [],
  order: undefined,
  status: 'idle',
  message: ''
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getOrders: function(state, action: PayloadAction<any>) {},
    changeStatusToIdle: function (state) {
      state.status = "idle";
      state.message = "";
    },
  },
  extraReducers: function (builder) {
    builder
      .addCase(fetchOrders.pending, function (state, action){
        state.status = "loading";
      })
      .addCase(fetchOrders.fulfilled, function (state, action){
        state.orders = action.payload.data.items;
        state.status = "fulfilled";
      })
      .addCase(fetchOrders.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Fetch order
      .addCase(fetchOrder.pending, function (state, action){
        state.status = "loading";
      })
      .addCase(fetchOrder.fulfilled, function (state, action){
        state.order = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(fetchOrder.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Update order
      .addCase(updateOrder.pending, function (state, action){
        state.status = "loading";
      })
      .addCase(updateOrder.fulfilled, function (state, action){
        state.order = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(updateOrder.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Upload File to  API
      .addCase(uploadFileAPI.pending, function (state, action){
        state.status = "loading";
      })
      .addCase(uploadFileAPI.fulfilled, function (state, action){
        state.order = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(uploadFileAPI.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Get Files
      .addCase(getFiles.pending, function (state, action){
        state.status = "loading";
      })
      .addCase(getFiles.fulfilled, function (state, action) {
        if(action.payload.downloadType === 'reception_file_paths') {
          state.reception_image_urls = action.payload.presignedUrls;
        } else if (action.payload.downloadType === 'delivery_file_paths'){
          state.delivery_image_urls = action.payload.presignedUrls;
        }
        state.status = "fulfilled";
      })
      .addCase(getFiles.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Delete file
      .addCase(deleteFile.pending, function (state, action){
        state.status = "loading";
      })
      .addCase(deleteFile.fulfilled, function (state, action){
        state.order = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(deleteFile.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      
  },
});

export const { getOrders, changeStatusToIdle } = ordersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export function selectOrders (state: RootState) { return state.orders };

export default ordersSlice.reducer;

import { createAppAsyncThunk } from '../../../hooks/redux';
import cutDesignApi from '../../../../apis/cutDesignApi';
import { User } from '../../../../models/auth';
import { UserPayload } from '../../../../models/user';

import { signOut } from '../auth/asyncTasks';
import { UNAUTHORIZED_ERROR_MESSAGE, UNAUTHORIZED_ERROR_TYPE } from '../../../../utils/types';

export const fetchUser = createAppAsyncThunk('users/fetchUser', async function (id: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;
        
        const res = await cutDesignApi.get(`/operators/${id}`, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
         
        res.data.user_group = res.data.user_group || 'Operator'
        return res;
       
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };
        
        return rejectWithValue(err.response.data);
    }
});

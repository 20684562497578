import React from 'react';
import { Link } from 'react-router-dom';

import { Dropdown } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';

import NotificationItem from './NotificationItem';

function Notifications (props: any) {

    const { notifications, markNotificationsSeen } = props;

    function renderNotificationsItems (notifications: any) {
        return notifications.map(function (notification: any) {
            return <NotificationItem key={notification.notification_id} notification={notification} />
        });
    };

    function isUnReadNotification (notifications: any) {
        const seenNotifications = notifications.filter(function (notification: any) { return notification.seen === false });
        return seenNotifications.length > 0 ? true : false;
    };

    return (
        <Dropdown className="nav-item main-header-notification d-flex" onClick={function () { markNotificationsSeen() }}>
            <Dropdown.Toggle className="new nav-link" variant="">
                <svg xmlns="http://www.w3.org/2000/svg" className="header-icon-svgs" width="24" height="24" viewBox="0 0 24 24">
                <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" />
                </svg>
                {
                    isUnReadNotification(notifications) ?
                        <span className="pulse"></span>
                    :
                        null
                }
            </Dropdown.Toggle>
            
            <Dropdown.Menu>
                <div className="menu-header-content text-start border-bottom">
                <div className="d-flex">
                    <h6 className="dropdown-title mb-1 tx-15 font-weight-semibold">
                    Notificaciones
                    </h6>
                </div>
                <p className="dropdown-title-text subtext mb-0 op-6 pb-0 tx-12 ">
                    {`Tienes ${notifications.length} notificaciones`}
                </p>
                </div>
                <Scrollbars style={{ height: 280 }}>
                    <div className="main-notification-list Notification-scroll">
                        {renderNotificationsItems(notifications)}
                    </div>
                </Scrollbars>

                {/* <div className="dropdown-footer">
                    <Link className="btn btn-primary btn-sm btn-block" to={"/dashboard/notifications"}>
                        Ver Todo
                    </Link>
                </div> */}

            </Dropdown.Menu>

        </Dropdown>
    );
};

export default Notifications;

import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { selectClients } from '../../../../app/store/slice/clients';
import { fetchClient } from '../../../../app/store/slice/clients/asyncTasks';


import ClientDetailComponent from '../../../../components/Clients/ClientDetail';
import Placeholder from '../../../../components/Placeholder';

function ClientDetail() {

    const params = useParams();
    const dispatch = useAppThunkDispatch();
    const { client, status } = useAppSelector(selectClients);

    useEffect(function () {
        dispatch(fetchClient(params.id));
    }, [dispatch, params.id]);

    return (
        <Fragment>
            {
                !client || status === "loading" ?
                    <Placeholder />
                    :
                    <ClientDetailComponent client={client} />
            }
        </Fragment>
    )
};

export default ClientDetail;

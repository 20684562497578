import React, { Fragment, useEffect } from 'react';
import { useAppThunkDispatch, useAppSelector } from '../../../app/hooks/redux';
import { selectClients } from '../../../app/store/slice/clients';
import { fetchClients } from '../../../app/store/slice/clients/asyncTasks';

import EmptyInfo from '../../../components/EmptyInfo';
import ClientsTable from '../../../components/Table/Clients';
import Placeholder from '../../../components/Placeholder';

import { CLIENTS_MODEL } from '../../../components/Table/utils/models';

import img from '../../../assets/img/png/grafico-de-barras.png';

function Clients () {

    const dispatch = useAppThunkDispatch();
    const { clients, status } = useAppSelector(selectClients);

    useEffect(function () {
        dispatch(fetchClients());
    }, [dispatch]);

    return (
        <Fragment>
            {
                status === "loading" ?
                    <Placeholder />
                : clients.length === 0 ?
                    <EmptyInfo img={img} link={"/dashboard/clients/create"} linkText={"Crea un cliente"} />
                :
                    <ClientsTable data={clients} columns={CLIENTS_MODEL} />
            }  
        </Fragment>
    )
}
export default Clients;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks/redux';
import { selectOperators } from '../../../../app/store/slice/operators';
import { useAppThunkDispatch } from '../../../../app/hooks/redux';
import { updateOperator } from '../../../../app/store/slice/operators/asyncTasks';
import { selectAuth } from '../../../../app/store/slice/auth';

import OperatorUpdateComponent from '../../../../components/Operators/OperatorUpdate';


function OperatorUpdate () {

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useAppThunkDispatch();
    const { operator } = useAppSelector(selectOperators);
    const { user } = useAppSelector(selectAuth);
   
    function onSubmit (formValues: any) {
        dispatch(updateOperator(formValues));
        navigate(`/dashboard/operators/${params.id}/detail`);
    }; 

    return (
            <OperatorUpdateComponent onSubmit={onSubmit} operator={operator} user={user}/>
    )
};

export default OperatorUpdate;

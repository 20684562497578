import React from 'react';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../app/hooks/redux';

import Input from '../../Form/Input';

import { requireField, errorTypes } from '../../../utils/formValidations';
import { selectQuotations } from '../../../app/store/slice/quotations';

import { Dropdown } from 'react-bootstrap';
import Select from '../../Form/Select'

function OrderTransferPaymentForm(props: any) {
  const { onSubmit } = props;
  const { quotations } = useAppSelector(selectQuotations);
  const { register, handleSubmit, formState: { errors } } = useForm();


  function renderQuotations (quotations: any) {
    return  <Select 
      className="form-control" 
      name={"quotationSelected"} 
      options={
        quotations.map( (item: any) => "ID: " + item.quotation_id + " Total: " + item.price)
      }
      values={quotations}
      label={"Seleccione Cotizacion Pagada"} 
      validation={{ required: requireField }} 
      errorMessages={[{ type: errorTypes.required, message: 'Por favor seleccionar cotizacion' }]}
      register={register} 
      errors={errors} 
    />
  };

  return (
      <div className="mx-auto d-block col">
        <div className="card border shadow-none card-body">
          <form className="form mx-auto d-block col" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <Input 
                  type={"number"} 
                  name={"payment_reference"} 
                  validation={{ 
                    required: requireField,
                  }} 
                  label={"Número de Referencia:"} 
                  errorMessages={[{ type: errorTypes.required, message: 'Ingrese el número de referencia de la transferencia' }]}
                  register={register} 
                  errors={errors} />
              { renderQuotations(quotations) }
              <Input 
                  type={"text"} 
                  name={"payment_extra_info"} 
                  label={"Concepto:"} 
                  errorMessages={[{ type: errorTypes.required, message: 'Ingrese el informacion extra' }]}
                  register={register} 
                  errors={errors} />    
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-block btn">Marcar como pagado</button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default OrderTransferPaymentForm;

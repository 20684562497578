import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/redux';
import { signOut } from '../../../app/store/slice/auth/asyncTasks';
import { selectAuth } from '../../../app/store/slice/auth';
import { selectUsers } from '../../../app/store/slice/user';

import SidebarComponent from '../../../components/Dashboard/Sidebar';


function Sidebar () {

    const dispatch = useAppDispatch();
    const { user } = useAppSelector(selectUsers)

    function logout () {
        dispatch(signOut());
    };

    return (
        <SidebarComponent logout={logout} user={user} />
    );
};

export default Sidebar;

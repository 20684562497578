import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { createOperator } from '../../../../app/store/slice/operators/asyncTasks';
import { selectAuth } from '../../../../app/store/slice/auth';

import OperatorCreateComponent from '../../../../components/Operators/OperatorCreate';

function OperatorCreate () {

    const navigate = useNavigate();
    const dispatch = useAppThunkDispatch();
    const { user } = useAppSelector(selectAuth);
    
    function onSubmit (formValues: any) {
        if (user.userGroup === "SuperUser" && formValues.user_group === false) {
            delete formValues.user_group;
        }
        dispatch(createOperator(formValues));
        navigate("/dashboard/operators");
    }; 

    return (
            <OperatorCreateComponent user={user} onSubmit={onSubmit} />
    )
};

export default OperatorCreate;

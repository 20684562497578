import React from 'react';
import { Routes, Route } from 'react-router-dom';

import OrdersContainer from '../../../containers/Dashboard/Orders';
import OrdersDetail from '../../../containers/Dashboard/Orders/OrderDetail';
import QuotationCreate from '../../../containers/Dashboard/Quotations/QuotationCreate';

function Orders () {

    return (
        <Routes>
            <Route path="/" element={<OrdersContainer />} />
            <Route path="/:id/:clientId/detail" element={<OrdersDetail />} />
            <Route path="/:id/:clientId/quotation/create" element={<QuotationCreate />} />
        </Routes>
    );
};

export default Orders;

import React, { Fragment } from 'react';

import Header from '../../Dashboard/Header';
import QuotationCreateForm from './QuotationCreateForm';
import QuotationsTable from '../../Table/Quotations';

import { QUOTATIONS_MODEL } from '../../Table/utils/models';

function QuotationCreate (props: any) {

    const { quotations, addQuotation, onSubmit } = props;

    return (
        <Fragment>
            <Header title="CREA TU COTIZACION"/>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card">
                        <div className="card-body p-5">
                            <div className="main-content-label mg-b-5">Crea las cotizaciones para esta orden</div>
                            <QuotationCreateForm quotations={quotations} addQuotation={addQuotation} onSubmit={onSubmit} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                    <QuotationsTable data={quotations} columns={QUOTATIONS_MODEL} />
                </div>
            </div>
        </Fragment>
    );
};

export default QuotationCreate;

import React from 'react';
import { Link } from 'react-router-dom';

function EmptyInfo (props: any) {
    
    const { img, } = props;

    return (
        <div className="m-5">
            <div className="card">
                <div className="d-flex justify-content-center p-1">
                    <div className="text-center">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h1 className='h1'>Hola, bienvenido</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 p-3">
                                    <img className="img-fluid" alt="not found" src={img} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col p-3">
                                    <h4>Aún no tienes cotizaciones.</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default EmptyInfo;

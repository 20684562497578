import React from "react";
import { Link } from "react-router-dom";

import { formatDateTimeForUserTimeZone } from '../../../utils/format';

export const ORDERS_MODEL: any = [
  {
    Header: "Compañia",
    accessor: "client_company_name",
    className: "text-center ",
  },
  {
    Header: "Email Cliente",
    accessor: "client_email",
    className: "text-center ",
  },
  {
    Header: "ID",
    accessor: "order_custom_id",
    className: "text-center ",
  },
  {
    Header: "Creacion Pedido",
    accessor: "creation_date",
    Cell: function (cell: any) {
      const date = formatDateTimeForUserTimeZone(cell.row.original.creation_date);
      return <span>{date}</span>
    },
    className: "text-center ",
  },
  {
    Header: "Estatus",
    accessor: "status",
    Cell: function (cell: any) { return <span className="badge badge-info-transparent">{cell.row.original.status}</span> },
    className: "text-center ",
  },
  {
    Header: "Notas",
    accessor: "notes",
    className: "text-center ",
  },
  {
    Header: "Detalle pedido",
    accessor: "detail",
    Cell: function (cell: any) { return <Link to={`/dashboard/orders/${cell.row.original.order_id}/${cell.row.original.client_id}/detail`} className="btn btn-primary btn-sm rounded-11 me-2 p-2">Ver más...</Link> },
    className: "text-center ",
  }
];

export const CLIENTS_MODEL: any = [
  {
    Header: "ID",
    accessor: "customer_custom_id",
    className: "text-center ",
  },
  {
    Header: "Email",
    accessor: "email",
    className: "text-center ",
  },
  {
    Header: "Compañia",
    accessor: "company_name",
    className: "text-center ",
  },
  {
    Header: "Telefono",
    accessor: "phone_number",
    className: "text-center ",
  },
  {
    Header: "Detalle del Cliente",
    accessor: "detail",
    Cell: function (cell: any) { return <Link to={`/dashboard/clients/${cell.row.original.id}/detail`} className="btn btn-primary btn-sm rounded-11 me-2 p-2">Ver más...</Link> },
    className: "text-center ",
  },
];

export const OPERATORS_MODEL: any = [
  {
    Header: "ID Operador",
    accessor: "id",
    className: "text-center ",
  },
  {
    Header: "Nombre",
    accessor: "name",
    className: "text-center ",
  },
  {
    Header: "Email",
    accessor: "email",
    className: "text-center ",
  },
  {
    Header: "Departamento",
    accessor: "department",
    className: "text-center ",
  },
  {
    Header: "Puesto",
    accessor: "job",
    className: "text-center ",
  },
  {
    Header: "Detalle del Cliente",
    accessor: "detail",
    Cell: function (cell: any) { return <Link to={`/dashboard/operators/${cell.row.original.id}/detail`} className="btn btn-primary btn-sm rounded-11 me-2 p-2">Ver más...</Link> },
    className: "text-center ",
  },
];

export const QUOTATIONS_MODEL: any = [
  {
    Header: "ID",
    accessor: "quotation_custom_id",
    className: "text-center ",
  },
  {
    Header: "Tipo",
    accessor: "quotation_type",
    className: "text-center ",
  },
  {
    Header: "Status",
    accessor: "status",
    className: "text-center ",
  },
  {
    Header: "Inicio Maquina",
    accessor: "machine_scheduled_date",
    Cell: function (cell: any) {
      const date = formatDateTimeForUserTimeZone(cell.row.original.machine_scheduled_date);
      return <span>{date}</span>
    },
    className: "text-center ",
  },
  {
    Header: "Tiempo Maquina",
    accessor: "machine_duration_minutes",
    className: "text-center ",
  },
  {
    Header: "Recepcion Material",
    accessor: "material_reception_date",
    Cell: function (cell: any) {
      const date = formatDateTimeForUserTimeZone(cell.row.original.material_reception_date);
      return <span>{date}</span>
    },
    className: "text-center ",
  },
  {

    Header: "Entrega",
    accessor: "due_date",
    Cell: function (cell: any) {
      const date = formatDateTimeForUserTimeZone(cell.row.original.due_date);
      return <span>{date}</span>
    },
    className: "text-center ",
  },
  {
    Header: "Precio",
    accessor: "price",
    className: "text-center ",
  },

];

export const GlobalFilter = ({ filter, setFilter }: any) => {
  return (
    <span className="d-flex ms-2">
      <input
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        className="form-control mb-4"
        placeholder="Buscar..."
      />
    </span>
  );
};

import React, { Fragment } from "react";

import { Modal } from 'react-bootstrap';


function Form (props: any) {
    const { showModal, title, type = '' ,form: Component, onSubmit, onDismiss } = props;
    return (
      <Fragment>
        <Modal show={showModal ? true : false} aria-labelledby="contained-modal-title-vcenter" centered>
          <div className='modal-header'>
            <button className="btn btn-close" onClick={function () { onDismiss() }}>
              x
            </button>
          </div>
          <div className='modal-body'>
            <div className="tx-center ">
              <h4 className="tx-semibold mg-b-22">{title}</h4>{" "}
                <Component onSubmit={onSubmit}
                  type={type}/>
              {" "}
              <button className="btn ripple btn-secondary btn pd-x-15" type="button" onClick={function () { onDismiss() }}>
                Cancelar
              </button>
              {" "}
            </div>
          </div>
        </Modal>
      </Fragment>
    )
}

export default Form;
import React from 'react';
import { useForm } from 'react-hook-form';

import TextArea from '../../Form/TextArea';

import { requireField, errorTypes } from '../../../utils/formValidations';

function OrderObservationsForm(props: any) {
  const { onSubmit, title, type } = props;
  const { register, handleSubmit, formState: { errors } } = useForm();


  return (
    <div className="row">
      <div className="mx-auto d-block col">
        <div className="card border shadow-none card-body">
          <h5 className="card-title mg-b-20">{title}</h5>
          <form className="form mx-auto d-block col" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <TextArea
                type={"text"}
                name={type}
                label={"Escribe aqui:"}
                validation={{
                  required: requireField,
                }}
                errorMessages={[{ type: errorTypes.required, message: 'Escriba sus observaciones' }, { type: errorTypes.validate, message: 'Escriba sus observacione', }]}
                register={register}
                errors={errors}
              />
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-block btn">Subir Commentarios</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrderObservationsForm;

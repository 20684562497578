import React, { Fragment, useEffect }from 'react';
import { useParams } from 'react-router-dom';
import { useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { selectOperators } from '../../../../app/store/slice/operators';
import { fetchOperator } from '../../../../app/store/slice/operators/asyncTasks';

import OperatorDetailComponent from '../../../../components/Operators/OperatorDetail';
import Placeholder from '../../../../components/Placeholder';

function OperatorDetail () {

    const params = useParams();
    const dispatch = useAppThunkDispatch();
    const { operator, status } = useAppSelector(selectOperators);
    
    useEffect(function (){
        dispatch(fetchOperator(params.id));
    }, [dispatch, params.id]);

    return (
        <Fragment>
            {
                !operator || status === "loading" ?
                    <Placeholder />
                :
                    <OperatorDetailComponent operator={operator}/>
            }    
        </Fragment>
    )
};

export default OperatorDetail;

import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';
import Checkbox from '../../Form/Checkbox';

import { requireField, errorTypes, auth } from '../../../utils/formValidations';

function OperatorCreateForm (props: any) {
    const { user, onSubmit } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input 
                type={"text"} 
                name={"name"} 
                validation={{ required: requireField }} 
                label={"Nombre completo"} 
                placeholder="Ingrese nombre completo" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese un nombre completo' }]} 
                register={register} 
                errors={errors} 
            />
            <Input 
                type={"text"} 
                name={"department"} 
                validation={{ required: requireField }} 
                label={"Departamento"} 
                placeholder="Departamento" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese un departamento' }]} 
                register={register} 
                errors={errors} 
            />
            <Input 
                type={"text"} 
                name={"job"} 
                validation={{ required: requireField }} 
                label={"Posición"} 
                placeholder="Posición" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese su posición' }]}  
                register={register} 
                errors={errors} 
            />
            <Input 
                type={"text"} 
                name={"phone_number"} 
                validation={{ required: requireField, pattern: auth.phoneFormat }} 
                label={"Número de teléfono"} 
                placeholder="Número de teléfono" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese un número de teléfono' }, { type: errorTypes.pattern, message: 'Número de teléfono inválido' }]}  
                register={register} 
                errors={errors} 
            />
            <Input 
                type={"text"} 
                name={"email"} 
                validation={{ required: requireField, pattern: auth.emailFormat }} 
                label={"Correo electrónico"} 
                placeholder="Correo electrónico" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese un email' }, { type: errorTypes.pattern, message: 'Formato de email inválido' }]} 
                register={register} 
                errors={errors} 
            />
            {
                user.userGroup !== "SuperUser" ?
                    null
                :
                    <Checkbox type={"checkbox"} name={"user_group"} value={"Admin"} label={"Haz click aquí si quieres dar de alta a un usuario admin"} errorMessage={"Ingrese un admin"} register={register} errors={errors} />
            }

            <button type="submit" className="btn btn-primary btn-block btn">Crear</button>
        </form>
    );
};

export default OperatorCreateForm;

import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';

import { requireField, auth, errorTypes } from '../../../utils/formValidations';

function ForgotPasswordForm (props: any) {
    const { onSubmit } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input
                type={"number"}
                name={"verificationCode"}
                validation={{ required: requireField, pattern: auth.confirmationCodeFormat }}
                label={"Código de Verificación"}
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese el código de confirmación' }, { type: errorTypes.pattern, message: 'Código de confirmación inválido' }]} 
                register={register}
                errors={errors}
            />
            <Input
                type={"password"}
                name={"newPassword"}
                validation={{ required: requireField, pattern: auth.passwordFormat }}
                label={"Nueva contraseña"}
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese una contraseña' }, { type: errorTypes.pattern, message: 'La contraseña debe contener una mayúscula, un número, un carácter especial y una longitud mínima de 8 dígitos' }]} 
                register={register}
                errors={errors}
            />
            <button type="submit" className="btn btn-primary btn-block btn">Confirmar nueva contraseña</button>
            {/* Special links */}
            <div className="mt-4 d-flex text-center justify-content-center mb-2">
            </div>
        </form>
    );
};

export default ForgotPasswordForm;

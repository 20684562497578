import { createAppAsyncThunk } from "../../../hooks/redux";
import cutDesignApi from '../../../../apis/cutDesignApi';
import { User } from '../../../../models/auth';

import { signOut } from "../auth/asyncTasks";

import { UNAUTHORIZED_ERROR_TYPE, UNAUTHORIZED_ERROR_MESSAGE } from '../../../../utils/types';

export const fetchOperators = createAppAsyncThunk('operators/fetchOperators', async function (_, { rejectWithValue, getState, dispatch }) {
    try {
        
        const user: User = getState().auth.user;

        const res = await cutDesignApi.get(`/operators`, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
      
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const fetchOperator = createAppAsyncThunk('operators/fetchOperator', async function (id: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;
        
        const res = await cutDesignApi.get(`/operators/${id}`, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
       
    } catch (err: any) {
       
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const createOperator = createAppAsyncThunk('operators/createOperator', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {
        const user: User = getState().auth.user;
        
        const res = await cutDesignApi.post(`/operators`, values, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const updateOperator = createAppAsyncThunk('operators/updateOperator', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {
        const user: User = getState().auth.user;
        const res = await cutDesignApi.put(`/operators/${values.id}`, values, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
        };
        
        return rejectWithValue(err.response.data);
    }
});

export function formatDateTimeForUserTimeZone(dateTimeString: any) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTimeInUserTimeZone = new Date(dateTimeString).toLocaleString(undefined, {
    timeZone: userTimeZone,
  });

  if (dateTimeInUserTimeZone === 'Invalid Date') {
    return "N/A"
  }

  return dateTimeInUserTimeZone;
};

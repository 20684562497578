import React, { Fragment, useEffect } from 'react';
import { useAppThunkDispatch, useAppSelector } from '../../../app/hooks/redux';
import { selectOperators } from '../../../app/store/slice/operators';
import { fetchOperators } from '../../../app/store/slice/operators/asyncTasks';

import EmptyInfo from '../../../components/EmptyInfo';
import OperatorsTable from '../../../components/Table/Operators';
import Placeholder from '../../../components/Placeholder';

import { OPERATORS_MODEL } from '../../../components/Table/utils/models';

import img from '../../../assets/img/png/grafico-de-barras.png';

function Operators () {

    const dispatch = useAppThunkDispatch();
    const { operators, status } = useAppSelector(selectOperators);

    useEffect(function () {
        dispatch(fetchOperators());
    }, [dispatch]);

    return (
        <Fragment>
            {
                status === "loading" ?
                    <Placeholder />
                : operators.length === 0 ?
                    <EmptyInfo img={img} link={"/dashboard/operators/create"} linkText={"Crea un operador"} />
                :
                    <OperatorsTable data={operators} columns={OPERATORS_MODEL} />
            }  
        </Fragment>
    )
}
export default Operators;

import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import Header from "../../Dashboard/Header";
// import DetailTable from "../../Table/Detail";

function ClientDetail (props: any) {

  const { id, email, phone_number, company_name, rfc, fa_street, fa_state, fa_number, fa_suburb, fa_locality, fa_zip_code, fa_city, tax_regime, cfdi} = props.client;
  
  return (
    <Fragment>
      <Header title='DETALLE DEL CLIENTE'/>
      <div className="row row-sm">
        <div className='col col-md-12 col-lg-12'>
          <div className="card custom-card">
            <div className='card-body'>
              <div className="d-lg-flex">
                <h6 className="main-content-label mb-1">
                  <span className="d-flex mb-4">
                    <Link to={'/dashboard/clients'}>
                      <img
                        src={require("../../../assets/img/brand/favicon-admin-dark.png")}
                        className="sign-favicon ht-40"
                        alt="logo"
                      />
                    </Link>
                  </span>
                </h6>
                <div className="ms-auto">
                  <p className="mb-1">
                    <span className="font-weight-bold">ID del cliente: {id}</span>
                  </p>
                </div>
              </div>
              <div className="row row-sm">
                <div className='col col-lg-6'>
                  <p className="h3">Email:</p>
                  <address>
                    {email}
                  </address>
                </div>
                <div className="col col-lg-6 text-end">
                  <p className="h3">Detalles generales:</p>
                  <div>
                    <p className="mb-1">
                      <span className="font-weight-bold fs-5">Estatus:</span>
                    </p>
                    <span className="badge badge-success-transparent fs-5">{"Activo"}</span>
                  </div>
                  <br />
                </div>
              </div>

              <div className="p-t-10">
                <div className="d-sm-flex">
                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>Organización</b></span>
                        <div>{company_name}</div>
                      </div>
                    </div>
                  </div>  
                    
                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>Teléfono</b></span>
                        <div>{phone_number}</div>
                      </div>
                    </div>
                  </div>  

                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>Información fiscal</b></span>
                        <div>{tax_regime}</div>
                      </div>
                    </div>
                  </div>  

                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>CFID</b></span>
                        <div>{cfdi}</div>
                      </div>
                    </div>
                  </div>  

                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>RFC</b></span>
                        <div>{rfc}</div>
                      </div>
                    </div>
                  </div>  
                  
                  </div>
                </div>

                <div className="p-t-10">
                  <div className="d-sm-flex">              

                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>Calle</b></span>
                        <div>{fa_street}</div>
                      </div>
                    </div>
                  </div>  

                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>Número</b></span>
                        <div>{fa_number}</div>
                      </div>
                    </div>
                  </div>  

                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>Ciudad</b></span>
                        <div>{fa_city}</div>
                      </div>
                    </div>
                  </div>  

                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>Estado</b></span>
                        <div>{fa_state}</div>
                      </div>
                    </div>
                  </div>  

                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>Colonia</b></span>
                        <div>{fa_suburb}</div>
                      </div>
                    </div>
                  </div> 

                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>Municipio</b></span>
                        <div>{fa_locality}</div>
                      </div>
                    </div>
                  </div> 

                  <div className="mg-sm-r-20 mg-b-10">
                    <div className="media">
                      <div className="media-body">
                        <span><b>Código postal</b></span>
                        <div>{fa_zip_code}</div>
                      </div>
                    </div>
                  </div>  
                  
                  </div>
                </div>

              <div className="table-responsive mg-t-40">
                {/* <DetailTable/> */}
              </div>
            </div>
            <div className="card-footer text-end">
            <Link 
                to={`/dashboard/clients/${id}/update`}
                className="btn ripple btn-warning mb-1 me-2"
              >
                <i className="fa fa-edit"></i> Modificar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ClientDetail;

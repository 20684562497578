import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';

import { requireField, auth, errorTypes } from '../../../utils/formValidations';

function SendForgotPasswordCodeForm (props: any) {
    const { onSubmit } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input 
                type={"email"} 
                name={"email"} 
                validation={{ required: requireField, pattern: auth.emailFormat }} 
                label={"Email"} 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese email' }, { type: errorTypes.pattern, message: 'Formato de email inválido' }]} 
                register={register} 
                errors={errors} 
            />
            <button type="submit" className="btn btn-primary btn-block btn">Enviar código</button>
            {/* Special links */}
            <div className="mt-4 d-flex text-center justify-content-center mb-2">
            </div>
            <div className="main-signin-footer text-center mt-3">
            </div>
        </form>
    );
};

export default SendForgotPasswordCodeForm;

import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';

import { selectOrders } from '../../../../app/store/slice/orders';
import { selectUsers } from '../../../../app/store/slice/user';
import { fetchOrder, uploadFileAPI, getFiles, deleteFile } from '../../../../app/store/slice/orders/asyncTasks';
import { selectQuotations } from '../../../../app/store/slice/quotations';
import { fetchQuotations } from '../../../../app/store/slice/quotations/asyncTasks';
import { updateOrder } from '../../../../app/store/slice/orders/asyncTasks';
import { updateQuotation } from '../../../../app/store/slice/quotations/asyncTasks';

import OrderUploadImageForm from '../../../../components/Orders/OrderUploadImageForm';
import OrderTransferPayment from '../../../../components/Orders/OrderTransferPayment';
import Form from '../../../../components/Modal/Form';
import OrderDetailComponent from '../../../../components/Orders/OrderDetail';
import Placeholder from '../../../../components/Placeholder';
import OrderObservationsForm from '../../../../components/Orders/OrderObservationsForm';

function OrderDetail() {
    const params = useParams();
    const dispatch = useAppThunkDispatch();
    const { user } = useAppSelector(selectUsers)
    const { order, status, reception_image_urls, delivery_image_urls } = useAppSelector(selectOrders);
    const { quotations } = useAppSelector(selectQuotations);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showObservationsModal, setShowObservationsModal] = useState(false);
    const [type, setType] = useState('');

    useEffect(function () {
        async function handleOrderFetchingDetail() {
            dispatch(fetchQuotations(params.id));

            const orderHelper = await dispatch(fetchOrder({ order_id: params.id, client_id: params.clientId })).unwrap();
            const order = orderHelper.data;

            await dispatch(getFiles({ client_id: order.client_id, paths: order.reception_file_paths, downloadType: 'reception_file_paths' }));
            await dispatch(getFiles({ client_id: order.client_id, paths: order.delivery_file_paths, downloadType: 'delivery_file_paths' }));

        }
        handleOrderFetchingDetail()

    }, [dispatch, params]);

    function handleUploadImage(type: string) {
        setType(type);
        setShowUploadModal(true);
    };

    function handleTransferPayment() {
        setShowTransferModal(true);
    };

    async function handleDownload(filePath: string, downloadType: string) {

        const { presignedUrl } = await dispatch(getFiles({ client_id: order.client_id, path: filePath, downloadType: downloadType })).unwrap();

        const link = document.createElement('a');
        link.href = presignedUrl;
        link.download = filePath;
        link.click();
    };

    async function handleDelete(filePath: string, type: string) {

        console.log('handleDelete', filePath, type);
        let { data: orderHelper }: any = await dispatch(deleteFile({
            client_id: order.client_id,
            order_id: order.order_id,
            reception_file_paths: order.reception_file_paths,
            delivery_file_paths: order.delivery_file_paths,
            path: filePath,
            deleteType: type,
        })).unwrap();

        console.log('OrderHelper', orderHelper.data);

        await dispatch(getFiles({
            client_id: orderHelper.client_id,
            paths: type === 'reception_file_paths' ? orderHelper.reception_file_paths : orderHelper.delivery_file_paths,
            downloadType: type
        }));
    };

    function onDismiss() {
        setShowUploadModal(false);
    };

    function onDismissTransferModal() {
        setShowTransferModal(false);
    };

    function onDismissObservationsModal() {
        setShowObservationsModal(false);
    };

    function handleObservations(type: string) {
        setType(type);
        setShowObservationsModal(true);
    }


    async function onSubmitObservations(formValues: any) {

        const { reception_observations, delivery_observations } = formValues

        let { data: orderHelper }: any = await dispatch(updateOrder({
            order_id: order.order_id,
            client_id: order.client_id,
            order: {
                reception_observations: reception_observations || order.reception_observations,
                delivery_observations: delivery_observations || order.delivery_observations
            }
        })).unwrap();

        onDismissObservationsModal();
    }

    async function onSubmit(formValues: any) {
        let { data: orderHelper }: any = await dispatch(uploadFileAPI({
            order_id: order.order_id,
            client_id: order.client_id,
            reception_file_paths: order.reception_file_paths || [],
            delivery_file_paths: order.delivery_file_paths || [],
            uploadType: type,
            formValues: formValues
        })).unwrap();

        await dispatch(getFiles({
            client_id: orderHelper.client_id,
            paths: type === 'reception_file_paths' ? orderHelper.reception_file_paths : orderHelper.delivery_file_paths,
            downloadType: type
        }));

        onDismiss();
    };

    function onSubmitTransferPayment(formValues: any) {

        const quotation = JSON.parse(formValues.quotationSelected);
        const { payment_reference, payment_extra_info } = formValues;


        dispatch(updateOrder({
            order_id: params.id,
            client_id: params.clientId,
            order: {
                status: "payed",
                payment_type: "wire_transfer",
                payment_reference: payment_reference,
                payment_extra_info: payment_extra_info
            },
        }));

        dispatch(updateQuotation({
            order_id: quotation.order_id,
            quotation_id: quotation.quotation_id,
            quotation: {
                status: "payed",
                payment_type: "wire_transfer",
                payment_reference: payment_reference,
                payment_extra_info: payment_extra_info
            },
        }));
        onDismissTransferModal();
    };

    function handleChangeStatus(status: string) {

        dispatch(updateOrder({
            order_id: params.id,
            client_id: params.clientId,
            order: {
                status: status,
            },
        }));

        if (status === 'in_progress' || status === 'done') {
            let quotation = quotations.filter((item: any) => ['payed', 'in_progress'].includes(item.status)).pop();

            dispatch(updateQuotation({
                order_id: quotation.order_id,
                quotation_id: quotation.quotation_id,
                quotation: {
                    status: status,
                },
            }));
        }
    };

    return (
        <Fragment>
            {
                !order || status === "idle" ?
                    <Placeholder />
                    :
                    <Fragment>
                        <OrderDetailComponent
                            user={user}
                            order={order}
                            reception_image_urls={reception_image_urls}
                            delivery_image_urls={delivery_image_urls}
                            quotations={quotations}
                            handleObservations={handleObservations}
                            handleUploadImage={handleUploadImage}
                            handleTransferPayment={handleTransferPayment}
                            handleDelete={handleDelete}
                            handleDownload={handleDownload}
                            handleChangeStatus={handleChangeStatus}
                        />
                        <Form
                            showModal={showUploadModal}
                            title={"Sube tu archivo"}
                            form={OrderUploadImageForm}
                            onSubmit={onSubmit}
                            onDismiss={onDismiss}
                        />
                        <Form
                            showModal={showObservationsModal}
                            title={"Agrega tus Observaciones"}
                            type={type}
                            form={OrderObservationsForm}
                            onSubmit={onSubmitObservations}
                            onDismiss={onDismissObservationsModal}
                        />
                        <Form
                            quotations={quotations}
                            showModal={showTransferModal}
                            title={"Pago por transferencia"}
                            form={OrderTransferPayment}
                            onSubmit={onSubmitTransferPayment}
                            onDismiss={onDismissTransferModal}
                        />
                    </Fragment>
            }
        </Fragment>
    )
}
export default OrderDetail;

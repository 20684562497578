import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { DashboardState } from '../../../../models/dashboard';

const initialState = {
  sidebarClassName: ""
};


export const dashboardSlice = createSlice({
  //this name is a reference to everything related to the slice
  name: 'dashboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // This reducers will become an action creator
    setSidebarClassName: function (state, action: PayloadAction<DashboardState>) {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.sidebarClassName = action.payload.value;
    },
    
  },
  
});

export const { setSidebarClassName } = dashboardSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDashboard = function (state: RootState) { return state.dashboard.sidebarClassName; };

export default dashboardSlice.reducer;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks/redux';
import { forgotPassword } from '../../app/store/slice/auth/asyncTasks';
import { selectAuth } from '../../app/store/slice/auth';

import ForgotPasswordComponent from '../../components/Auth/ForgotPassword'

function ForgotPassword () {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(selectAuth);

    function onSubmit (formValues: any) {
        const forgotPasswordValues: Object = {
            email: user.email,
            verificationCode: formValues.verificationCode,
            newPassword: formValues.newPassword,
        }
        dispatch(forgotPassword(forgotPasswordValues));
        navigate("/");
    };

    return (
        <ForgotPasswordComponent onSubmit={onSubmit} />
    );
};

export default ForgotPassword;

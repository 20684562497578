import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState} from '../..';
import { ClientsState } from '../../../../models/clients';
import { fetchClients, fetchClient, createClient, updateClient } from './asyncTasks';

const initialState: ClientsState = {
  clients: [],
  client: undefined,
  status: 'idle',
  message: '',
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    getClients: function(state, action: PayloadAction<any>) {},
    changeStatusToIdle: function (state) {
      state.status = "idle";
      state.message = "";
    },
  },
  extraReducers: function (builder) {
    builder
      .addCase(fetchClients.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(fetchClients.fulfilled, function (state, action){
        state.clients = action.payload.data.items;
        state.status = "fulfilled";
      })
      .addCase(fetchClients.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Fetch client
      .addCase(fetchClient.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(fetchClient.fulfilled, function (state, action){
        state.client = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(fetchClient.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Create client
      .addCase(createClient.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(createClient.fulfilled, function (state, action){
        state.client = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(createClient.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Update client
      .addCase(updateClient.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(updateClient.fulfilled, function (state, action){
        state.client = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(updateClient.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
  },
});

export const { getClients, changeStatusToIdle } = clientsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export function selectClients (state: RootState) { return state.clients };

export default clientsSlice.reducer;

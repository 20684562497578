import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAppSelector } from '../app/hooks/redux';
import { selectAuth } from '../app/store/slice/auth';

// Layouts
import Auth from './Auth';
import Dashboard from './Dashboard';

// Components
import Error404 from '../components/Error/404';

// Protected Routes
import ProtectedAuthRoute from '../routes/auth/ProtectedAuthRoute';
import ProtectedDashboardRoute from '../routes/ProtectedDashboardRoute';

function App() {

  const { user } = useAppSelector(selectAuth);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/*" element={
            <ProtectedAuthRoute user={user}>
              <Auth />
            </ProtectedAuthRoute>
          } />   
          <Route path="/dashboard/*" element={
            <ProtectedDashboardRoute user={user}>
              <Dashboard />
            </ProtectedDashboardRoute>
          } />
          <Route path="*" element={<Error404 />} />     
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

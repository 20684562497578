import React from "react";
import { Link } from "react-router-dom";

import Header from "../Dashboard/Header";

function Profile(props: any) {

  const { name, email, department, job, phone_number, user_group } = props.user;

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <Header title="PERFIL" />
      {/* <!-- /breadcrumb --> */}

      <div className="row">
        <div className="col col-lg-12 col-md-12">
          <div className="card custom-card customs-cards">
            <div className="card-body d-md-flex bg-white">
              <div className="">
                <span className="profile-image pos-relative">
                  <img
                    className="br-5"
                    alt="avatar img"
                    src={require("../../assets/img/faces/avatar.png")}
                  />
                </span>
              </div>
              <div className="my-md-auto mt-4 prof-details">
                <h4 className="font-weight-semibold ms-md-4 ms-0 mb-1 pb-0">
                  {name}
                </h4>

                
                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <span>
                    <i className="fa fa-address-book me-2"></i>
                  </span>
                  <span className="font-weight-semibold me-2">Tipo de usuario:</span>
                  <span>{user_group}</span>
                </p>

                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <span>
                    <i className="fa fa-phone me-2"></i>
                  </span>
                  <span className="font-weight-semibold me-2">Teléfono:</span>
                  <span>{phone_number}</span>
                </p>

                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <span>
                    <i className="fa fa-envelope me-2"></i>
                  </span>
                  <span className="font-weight-semibold me-2">Email:</span>
                  <span>{email}</span>
                </p>

                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <span>
                    <i className="fa fa-address-book me-2"></i>
                  </span>
                  <span className="font-weight-semibold me-2">Departamento:</span>
                  <span>{department}</span>
                </p>

                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <span>
                    <i className="fa fa-address-book me-2"></i>
                  </span>
                  <span className="font-weight-semibold me-2">Puesto:</span>
                  <span>{job}</span>
                </p>

                <Link to={'/dashboard/profile/change-password'} className="btn btn-success ms-md-4 ms-0 mb-2">
                  Cambiar contraseña
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>
  );
}

export default Profile;

import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';

import { requireField, errorTypes, auth} from '../../../utils/formValidations';

function NewPasswordForm (props: any) {

    const { onSubmit } = props;
    const { register, handleSubmit, formState: { errors }, getValues } = useForm();


    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input
                type={"password"}
                name={"password"}
                validation={{ required:requireField }}
                label={"Ingresa tu contraseña"} 
                errorMessages={[{ type: errorTypes.required, message: "Ingrese su contraseña actual" }]}
                register={register}
                errors={errors}
                placeholder={"Ingrese su contraseña actual"}
            />
            <Input
                type={"password"}
                name={"newPassword"}
                validation={{
                    required:requireField,
                    pattern: auth.passwordFormat,
                }}
                label={"Ingresa una nueva contraseña"} 
                errorMessages={[{ type: errorTypes.required, message: "La contraseña debe conformar con al menos 8 valores alfanuméricos con al menos una mayúscula y un caracter especial" }, { type: errorTypes.pattern, message: 'Formato de Password inválido' }]}
                register={register}
                errors={errors}
                placeholder={"Ingrese su nueva contraseña"}
            />
            <Input
                type={"password"}
                name={"confirmPassword"}
                validation={{
                    required:requireField,
                    validate: function (value: any) { return value === getValues("newPassword") },
                }}
                label={"Confirma tu nueva contraseña"}
                errorMessages={[{ type: errorTypes.required, message: "Confirme nueva contraseña" }, { type: errorTypes.validate, message: 'Las contraseñas no coinciden' }]}
                register={register}
                errors={errors}
                placeholder={"Confirme su nueva contraseña"}
            />
            <button type="submit" className="btn btn-primary btn-block btn">Cambiar contraseña</button>
            {/* Special links */}
            <div className="mt-4 d-flex text-center justify-content-center mb-2">
            </div>
            <div className="main-signin-footer text-center mt-3">
            </div>
        </form>
    );
};

export default NewPasswordForm;

// Order posible statuses (
// 	Unknown          "unknown"
// 	PendingQuotation "pending_quotation"
// 	AwaitingPayment  "awaiting_payment"
// 	Payed            "payed"
// 	InProgress       "in_progress"
// 	Done             "done"
// 	Delivered        "delivered"
// 	Canceled         "canceled"
// )

export const validateStatus = (statuses: Array<string>, status: string ) => {
    if (statuses.includes(status)) {
        return true;
    }
    return false;
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { NotificationsState } from '../../../../models/notifications';
import { fetchNotifications, updateNotificationsBatch } from './asyncTasks';

const initialState: NotificationsState = {
  notifications: [],
  status: 'idle',
  message: ''
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getNotifications: function(state, action: PayloadAction<any>) {}
  },
  extraReducers: function (builder) {
    builder
      .addCase(fetchNotifications.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(fetchNotifications.fulfilled, function (state, action){
        state.notifications = action.payload.data.items;
        state.status = "fulfilled";
      })
      .addCase(fetchNotifications.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      .addCase(updateNotificationsBatch.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(updateNotificationsBatch.fulfilled, function (state, action){
        state.notifications = action.payload.data.items;
        state.status = "fulfilled";
      })
      .addCase(updateNotificationsBatch.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
  },
});

export const { getNotifications } = notificationsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export function selectNotifications (state: RootState) {return state.notifications};

export default notificationsSlice.reducer;

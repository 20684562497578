import React, { Fragment } from 'react';

import Scrollbars from 'react-custom-scrollbars';
import { NavLink, Link } from 'react-router-dom';

import { roleDeptValidation, roleValidation } from '../../utils/privilegeValidation';
function Sidebar(props: any) {

    const { logout, user } = props;

    return (
        <Fragment>
            <div className="sticky">
                <aside className="app-sidebar">
                    <Scrollbars className="hor-scroll" style={{ position: "absolute" }}>
                        <div className="main-sidebar-header active">
                            <NavLink className="header-logo active" to={`${process.env.PUBLIC_URL}/dashboard/orders`}>
                                <img
                                    src={require("../../assets/img/brand/admin-word-dark.png")}
                                    className="main-logo  desktop-logo"
                                    alt="logo"
                                />
                            </NavLink>
                        </div>
                        <div className="main-sidemenu">
                            <div className="slide-left disabled" id="slide-left">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#7b8191"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                                </svg>
                            </div>

                            <ul className="side-menu">
                                <li className="side-item side-item-category">MAIN</li>
                                <li className="slide">
                                    <Link className="side-menu__item" to={'/dashboard/orders'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24">
                                            <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path>
                                        </svg>
                                        <span className="side-menu__label">Pedidos</span>
                                    </Link>
                                </li>
                                <li className="slide">
                                    {
                                        roleDeptValidation(['SuperUser', 'Admin', 'Operators'], ['Admin', 'Administracion', 'Ventas'], user) ?
                                            <Link className="side-menu__item" to={'/dashboard/clients'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24">
                                                    <path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>
                                                </svg>
                                                <span className="side-menu__label">Clientes</span>
                                            </Link>

                                            :
                                            null

                                    }

                                </li>
                                <li className="slide">
                                    {
                                        roleValidation(['SuperUser', 'Admin'], user) ?
                                            <Link className="side-menu__item" to={'/dashboard/operators'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24">
                                                    <path d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zm0 2 .001 4H5V5h14zM5 11h8v8H5v-8zm10 8v-8h4.001l.001 8H15z"></path>
                                                </svg>
                                                <span className="side-menu__label">Operadores</span>
                                            </Link>
                                            :
                                            null
                                    }
                                </li>
                                <li className="slide">
                                    {
                                        roleDeptValidation(['SuperUser', 'Admin', 'Operators'], ['Admin', 'Administracion', 'Ventas'], user) ?
                                            <Link className="side-menu__item" to={'/dashboard/gantt-chart'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24">
                                                    <path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>
                                                </svg>
                                                <span className="side-menu__label">Gráfica Gantt</span>
                                            </Link>
                                            :
                                            null
                                    }
                                </li>

                                <li className="slide">
                                    <Link className="side-menu__item" to="#" onClick={function () { logout() }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24">
                                            <path d="M12 22c4.879 0 9-4.121 9-9s-4.121-9-9-9-9 4.121-9 9 4.121 9 9 9zm0-16c3.794 0 7 3.206 7 7s-3.206 7-7 7-7-3.206-7-7 3.206-7 7-7zm5.284-2.293 1.412-1.416 3.01 3-1.413 1.417zM5.282 2.294 6.7 3.706l-2.99 3-1.417-1.413z"></path><path d="M11 9h2v5h-2zm0 6h2v2h-2z"></path>
                                        </svg>
                                        <span className="side-menu__label">Cerrar sesión</span>
                                    </Link>
                                </li>
                            </ul>
                            <div className="slide-right" id="slide-right">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#7b8191"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                                </svg>
                            </div>

                        </div>
                    </Scrollbars>
                </aside>
            </div>
        </Fragment>
    );
};

export default Sidebar;

import React from 'react';

function Input (props: any) {

    const { type, name, validation, label, placeholder, errorMessages, register, errors } = props;

    return (
        <div className="form-group">
            <label className="form-label fs-6">{label}</label>
            <input
                placeholder={placeholder}
                className="form-control"
                type={type}
                {...register(name, validation)}
            />
            {errorMessages.map(function(errorMessage: any){
                return errors[name] && errors[name].type === errorMessage.type && <p key={errorMessage} className="text text-danger">{errorMessage.message}</p>
            })}
        </div>
    );
};

export default Input;

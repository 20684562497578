import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';
import Checkbox from '../../Form/Checkbox';

import { requireField, errorTypes, auth } from '../../../utils/formValidations';

function ClientUpdateForm (props: any) {
    const { onSubmit, user } = props;
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: props.client
    });

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>    
            <Input 
                type={"text"} 
                name={"company_name"} 
                validation={{ required: requireField }} 
                label={"Nombre de la compañia"} 
                placeholder="Ingrese nombre de la compañia" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese nombre de la compañia' }]}  
                register={register} 
                errors={errors} 
            />
            <label className="form-label fs-6">Dirección fiscal</label>
            <div className="form-group">
              <div className="row-sm row">
                <div className="col-sm-6">
                  <Input 
                    type={"text"} 
                    name={"fa_street"} 
                    validation={{ required: requireField }} 
                    label={"Calle"} 
                    placeholder="Calle" 
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese el nombre de la calle de su domicilio fiscal' }]} 
                    register={register} 
                    errors={errors} 
                  />
                </div>
                <div className="mg-t-10 mg-sm-t-0 col-sm-6">
                  <Input 
                    type={"number"} 
                    name={"fa_number"} 
                    validation={{ required: requireField }} 
                    label={"Nro de calle"} 
                    placeholder="Nro de calle" 
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese el número de la calle de su domicilio fiscal' }]} 
                    register={register} 
                    errors={errors} 
                  />
                </div>
                <div className="col-sm-6">
                  <Input 
                    type={"text"} 
                    name={"fa_suburb"} 
                    validation={{ required: requireField }} 
                    label={"Colonia"} 
                    placeholder="Colonia" 
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese la colonia de su domicilio fiscal' }]} 
                    register={register} 
                    errors={errors} 
                  />
                </div>
                <div className="mg-t-10 mg-sm-t-0 col-sm-6">
                  <Input 
                    type={"text"} 
                    name={"fa_zip_code"} 
                    validation={{ required: requireField }} 
                    label={"Código postal"} 
                    placeholder="Código postal" 
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese la colonia de su domicilio fiscal' }]} 
                    register={register} 
                    errors={errors} 
                  />
                </div>
                <div className="col-sm-6">
                  <Input 
                    type={"text"} 
                    name={"fa_city"} 
                    validation={{ required: requireField }} 
                    label={"Ciudad"} 
                    placeholder="Ciudad" 
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese la ciudad de su domicilio fiscal' }]} 
                    register={register} 
                    errors={errors} 
                  />
                </div>
                <div className="mg-t-10 mg-sm-t-0 col-sm-6">
                  <Input 
                    type={"text"} 
                    name={"fa_locality"} 
                    validation={{ required: requireField }} 
                    label={"Municipio"} 
                    placeholder="Municipio" 
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese el municipio de su domicilio fiscal' }]} 
                    register={register} 
                    errors={errors} 
                  />
                </div>
              </div>
            </div>
            <Input 
                type={"text"} 
                name={"fa_state"} 
                validation={{ required: requireField }}
                label={"Estado"} 
                placeholder="Estado" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese el estado de su domicilio fiscal' }]}  
                register={register} 
                errors={errors} 
            />
            <Input 
                type={"text"} 
                name={"billing_information"} 
                validation={{ required: requireField }} 
                label={"Datos de facturación"} 
                placeholder="Ingrese datos de facturación" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese sus datos de facturación' }]} 
                register={register} 
                errors={errors} 
            />
            <Input 
                type={"text"} 
                name={"phone_number"} 
                validation={{ required: requireField, pattern:auth.phoneFormat }} 
                label={"Número de teléfono"} 
                placeholder="Ingrese número de teléfono" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese un número de teléfono' }, { type: errorTypes.pattern, message: 'Número de teléfono inválido' }]}  
                register={register} 
                errors={errors} 
            />
            {
                user.userGroup === 'Operator'?
                    null
                :
                    <Checkbox type={"checkbox"} name={"disabled"} label={"Desactivar usuario"} errorMessage={"Seleccione el checkbox"} register={register} errors={errors} />
            }
            
            <button type="submit" className="btn btn-primary btn-block btn">Modificar</button>
        </form>
    );
};

export default ClientUpdateForm;

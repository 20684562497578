
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import UsersDetail from '../../../containers/Dashboard/User/UserDetail';
import ChangePassword from '../../../containers/Auth/ChangePassword';

function Profile () {

    return (
        <Routes>
            <Route path="/" element={<UsersDetail />} />
            <Route path="/change-password" element={<ChangePassword />} />
        </Routes>
    );
};

export default Profile;

export const requireField = true;

export const errorTypes = {
    required: 'required',
    pattern: 'pattern',
    validate: 'validate',
    min: 'min',
};

export const auth = {
    emailFormat: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    passwordFormat: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#$@!%&*?.,+]).{8,20}$/,
    confirmationCodeFormat: /^[0-9]{6}$/g,
    phoneFormat: /^[0-9]{10}$/g,
};

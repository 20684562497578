import React from 'react';

function Checkbox (props: any) {

    const { type, name, value, label, errorMessage, register, errors } = props;

    return (
        <div className="checkbox">
            <div className="d-flex">
            <input
                id={name}
                className="form-label"
                type={type}
                {...register(name)}
                value={value}
            />
            <label className="mt-5 mb-5  mx-2 form-label fs-6" htmlFor={name}>{label}</label>
            {errors[name] && <p className="text text-danger">{errorMessage}</p>}
            </div>
        </div>
    );
};

export default Checkbox;

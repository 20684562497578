import React from 'react';
import { Link } from 'react-router-dom';

function Error404() {
    return (
        <div>
            <div className="square-box"><div></div> <div></div> <div></div> <div></div> <div></div> <div></div><div></div> <div></div> <div></div> <div></div> <div></div> <div></div><div></div> <div></div> <div></div></div>
            <div className="page bg-primary">

                {/* <!-- Main-error-wrapper --> */}
                <div className="main-error-wrapper page page-h">
                    <div>
                        <h1 className="text-white">
                            404<span className="tx-20">error</span>
                        </h1>
                        <h2 className="text-white">
                            Oopps. La página que buscas no está
                        </h2>
                        <h6 className="tx-white-6">
                            Tal vez escribiste mal el link
                        </h6>
                        <Link
                            className="btn btn-light"
                            to={`${process.env.PUBLIC_URL}/`}
                        >
                            Inicio
                        </Link>
                    </div>
                </div>
                {/* <!-- /Main-error-wrapper --> */}
            </div>
        </div>
    );
};

export default Error404;

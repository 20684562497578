import { configureStore, combineReducers, ThunkAction,
    Action, CombinedState, Reducer, ThunkDispatch, AnyAction
} from '@reduxjs/toolkit';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';


import authReducer from './slice/auth';
import dashboardReducer from './slice/dashboard';
import ordersReducer from './slice/orders';
import clientsReducer from './slice/clients';
import operatorsReducer from './slice/operators';
import quotationsReducer from './slice/quotations';
import usersReducer from './slice/user';
import notificationsReducer from './slice/notifications';

export const combinedReducers = combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    orders: ordersReducer,
    clients: clientsReducer,
    operators: operatorsReducer,
    quotations: quotationsReducer,
    users: usersReducer,
    notifications: notificationsReducer,
});

function rootReducer (state: ReturnType<Reducer>, action: Action): CombinedState<any> {
    if (action.type === 'auth/cleanState') {
        storage.removeItem('persist:root')
        state = undefined
    }
    return combinedReducers(state, action);
};

/**
 * 
 * Redux persist configuration
 * 
 */
const persistConfig: PersistConfig<any> = {
    key: 'root',
    storage,
    transforms: [
        encryptTransform({
            secretKey: 'my-super-secret-key',
            onError: (err) => {
                // Handle the error
                console.log(err);
            }
        }),
    ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
	    }),
    devTools: process.env.NODE_ENV !== "development" ? false : true,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type RootState = ReturnType<typeof store.getState>;

// This AppThunk works with thunk actions which are not declared
// with creatAsyncThunk method
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { QuotationsState } from '../../../../models/quotations';
import { fetchQuotations, fetchQuotationsByDate, createQuotations, updateQuotation } from './asyncTasks';

const initialState: QuotationsState = {
  quotations: [],
  quotation: undefined,
  status: 'idle',
  message: ''
};

export const quotationsSlice = createSlice({
  name: 'quotations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getQuotations: function (state, action: PayloadAction<any>) { }
  },
  extraReducers: function (builder) {
    builder
      .addCase(fetchQuotations.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(fetchQuotations.fulfilled, function (state, action) {
        state.quotations = action.payload.data.items;
        state.status = "fulfilled";
      })
      .addCase(fetchQuotations.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Fetch by date
      .addCase(fetchQuotationsByDate.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(fetchQuotationsByDate.fulfilled, function (state, action) {
        state.status = "fulfilled"
        state.quotations = action.payload.data.items;
      })
      .addCase(fetchQuotationsByDate.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Create
      .addCase(createQuotations.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(createQuotations.fulfilled, function (state, action) {
        state.quotations = action.payload.data.items;
        state.status = "fulfilled";
      })
      .addCase(createQuotations.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Update quotation
       .addCase(updateQuotation.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(updateQuotation.fulfilled, function (state, action) {
          state.quotations = (state.quotations as Array<any>).map(quotation =>
            quotation.quotation_id === action.payload.data.quotation_id
              ? action.payload.data
              : quotation
          );
          state.status = 'fulfilled';
          console.log(state.quotations);
        }
      )
      .addCase(updateQuotation.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
  },
});

export const { getQuotations } = quotationsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export function selectQuotations(state: RootState) { return state.quotations };

export default quotationsSlice.reducer;

import React, { Fragment} from 'react';

import Header from '../../Dashboard/Header';
import OperatorCreateForm from './OperatorCreateForm';


function OperatorCreate (props: any) {

  const { user, onSubmit } = props;

  return (
    <Fragment>
      <Header title="CREA UN OPERADOR"/>
      <div className="row">
        <div className="col col-lg-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="main-content-label mg-b-5 fs-6">Creación del operador</div>
              <div className="row">
              <div className="mx-auto d-block col-xl-6 col-lg-8 col-md-10">
                <div className="card pd-20 pd-md-40 card-body">
                  <h5 className="card-title mg-b-20 fs-6">Crea un operador</h5>
                  <OperatorCreateForm user={user} onSubmit={onSubmit} />
                </div>
              </div>
            </div>  
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};


export default OperatorCreate;

import React, { useRef } from 'react';
import CardItem from './CardItem';
import { useReactToPrint } from 'react-to-print';
import { roleDeptValidation } from '../../../utils/privilegeValidation';
import { validateStatus } from '../../../utils/statusValidation'

function FilesSection(props: any) {


    const { handleDownload, handleUploadImage, order, delivery_image_urls, reception_image_urls, handleDelete, handleObservations, user } = props;

    const receptionRef = useRef(null);
    const deliveryRef = useRef(null);

    const handleReceptionPrint = useReactToPrint({
        content: () => receptionRef.current,
    });

    const handleDeliveryPrint = useReactToPrint({
        content: () => deliveryRef.current,
    });

    function renderImages(image_urls: string[], file_paths: string[], type: string) {
        let cardItems;

        if (image_urls && file_paths) {
            cardItems = image_urls.map((image_url, index) => {
                return <CardItem key={file_paths[index]}
                    file_path={file_paths[index]}
                    image_url={image_url}
                    type={type}
                    handleDownload={handleDownload}
                    handleDelete={handleDelete}
                />
            });
        }

        return cardItems
    }


    function renderReceptionFunctions() {
        if (validateStatus(['pending_quotation', 'awaiting_payment', 'payed', 'done', 'delivered', 'canceled'], order.status)) {
            return null
        }

        if (roleDeptValidation(['SuperUser', 'Admin', 'Operator'], ['Admin', 'Administrador', 'Vendedor', 'Almacenista'], user)) {
            return <>
                <button
                    type="button"
                    className="btn ripple btn-info mb-1 me-2"
                    onClick={function () { handleUploadImage("reception_file_paths") }}
                >
                    <i className="fe fe-upload me-1"></i> Subir Evidencia Material
                </button>
                <button
                    type="button"
                    className="btn ripple btn-warning mb-1 me-2"
                    onClick={function () { handleObservations("reception_observations") }}
                >
                    <i className="fa fa-align-left me-1"></i>Observaciones recepción
                </button>
            </>
        }

        return null
    }


    function renderDeliveryFunctions() {
        if (validateStatus(['pending_quotation', 'awaiting_payment', 'delivered', 'done', 'canceled'], order.status)) {
            return null
        }

        if (roleDeptValidation(['SuperUser', 'Admin', 'Operator'], ['Admin', 'Administrador', 'Vendedor', 'Almacenista'], user)) {
            return <>
                <button
                    type="button"
                    className="btn ripple btn-info mb-1 me-2"
                    onClick={function () { handleUploadImage("delivery_file_paths") }}
                >
                    <i className="fe fe-upload me-1"></i> Subir Evidencia de salida
                </button>
                <button
                    type="button"
                    className="btn ripple btn-warning mb-1 me-2"
                    onClick={function () { handleObservations("delivery_observations") }}
                >
                    <i className="fa fa-align-left me-1"></i>Observaciones salida
                </button>
            </>
        }
        return null


    }

    return (
        <div id="accordion">
            <div className="card">
                <div className="card-header" id="headingOne">
                    <h3 className="mb-0">
                        <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            DFX
                        </button>
                    </h3>
                </div>
                <div id="collapseOne" className="border collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">
                        <button
                            type="button"
                            className="btn ripple btn-success mb-1 me-2"
                            onClick={function () { handleDownload(order.order_file_path, 'singleFile') }}
                        >
                            <i className="fe fe-download me-1"></i> Descargar DFX
                        </button>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingTwo">
                    <h3 className="mb-0">
                        <p className="text-center" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Archivos de evidencia recepción de orden
                        </p>
                    </h3>
                </div>
                <div id="collapseTwo" className="border collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div className="card-body">
                        <div className="row row-sm" data-bs-spy="scroll" data-bs-offset="0">
                            {
                                renderImages(reception_image_urls, order.reception_file_paths, 'reception_file_paths')
                            }
                        </div>
                        <div>
                            <h5 className="">Observaciones de recepción:</h5>
                            {
                                order.delivery_observations ?
                                    <p className="">{order.reception_observations}</p>
                                    :
                                    <p className=""> Ninguna Observacion</p>
                            }
                        </div>
                        {
                            renderReceptionFunctions()
                        }
                        <button
                            type="button"
                            className="btn ripple btn-light mb-1 me-2"
                            onClick={handleReceptionPrint}
                        >
                            <i className="fa fa-print"></i> Imprimir Recibo Recepcion
                        </button>
                        {/* Reception Print Component */}
                        <div style={{ display: "none" }}>
                            <div ref={receptionRef} className="m-5">
                                <div className="card">
                                    <div className="d-flex justify-content-center p-1">
                                        <div className="text-center">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h1 className='h1'>Recibo de Recepcion De Material</h1>
                                                        <h5>Fecha: {Date()}</h5>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col p-1">
                                                        <h5>Pedido: {order.order_id}</h5>
                                                    </div>
                                                    <div className="col p-1">
                                                        <h5>Cliente: {order.client_id}</h5>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col">
                                                        <p>
                                                            Estoy de las condiciones con las que entrego el material para mi pedido.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        _________________________________________________
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        Firma y Nombre Completo.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Print Component */}
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingThree">
                    <h3 className="mb-0">
                        <p className="text-center" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Archivo de evidencia de entrega.
                        </p>
                    </h3>
                </div>
                <div id="collapseThree" className="border collapse show" aria-labelledby="headingThree" data-parent="#accordion">
                    <div className="card-body">
                        <div className="row row-sm" data-bs-spy="scroll" data-bs-offset="0">
                            {
                                renderImages(delivery_image_urls, order.delivery_file_paths, 'delivery_file_paths')
                            }
                        </div>
                        <div>
                            <h5 className="">Observaciones de entrega:</h5>
                            {
                                order.delivery_observations ?
                                    <p className="">{order.delivery_observations}</p>
                                    :
                                    <p className=""> Ninguna Observacion</p>
                            }
                        </div>
                        {
                            renderDeliveryFunctions()
                        }
                        <button
                            type="button"
                            className="btn ripple btn-light mb-1 me-2"
                            onClick={handleDeliveryPrint}
                        >
                            <i className="fa fa-print"></i> Imprimir Recibo Entrega
                        </button>
                        {/* Delivery Print Component */}
                        <div style={{ display: "none" }}>
                            <div ref={deliveryRef} className="m-5">
                                <div className="card">
                                    <div className="d-flex justify-content-center p-1">
                                        <div className="text-center">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h1 className='h1'>Recibo de Entrega De Material</h1>
                                                        <h5>Fecha: {Date()}</h5>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col p-1">
                                                        <h5>Pedido: {order.order_id}</h5>
                                                    </div>
                                                    <div className="col p-1">
                                                        <h5>Cliente: {order.client_id}</h5>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col">
                                                        <p>
                                                            Estoy de las condiciones con las que recibo el producto final de mi pedido.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        _________________________________________________
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        Firma y Nombre Completo
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Print Component */}
                    </div>
                </div>
            </div >
        </div>
    );
};

export default FilesSection;

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Dashboard/Header';

import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
} from 'react-table';
import { GlobalFilter } from '../utils/models';

function ClientsTable(props: any) {
    const { columns, data } = props;

    
    const tableInstance = useTable(
        {
            columns: columns,
            data: data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    
    const {
        getTableProps, // table props from react-table
        headerGroups, // headerGroups, if your table has groupings
        getTableBodyProps, // table body props from react-table
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        state,
        setGlobalFilter,
        page, // use, page or rows
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
    }: any = tableInstance;

    const { globalFilter, pageIndex, pageSize }:any = state;

    return (
        <Fragment>
            <Header title='CLIENTES'/>
            <div className='row'>
                <div className="col col-sm-12">
                    <div className='card'>
                        <div className='card-header'>
                            <h4 className="card-title">Resumen de clientes</h4>
                        </div>
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                <>
                                    <div className="d-flex">
                                        <select className=" mb-4 selectpage border me-1"  value={pageSize} onChange={(e: any) => setPageSize(Number(e.target.value))}>
                                            {[10, 25, 50].map((pageSize) => (
                                                <option key={pageSize} value={pageSize}>
                                                    Mostrar {pageSize}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="d-flex ms-auto mb-4">
                                            <Link className="btn btn-primary" to={"/dashboard/clients/create"}>Crear cliente</Link>
                                        </div>
                                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                    </div>
                                    <table {...getTableProps()} className="table table-bordered text-nowrap mb-0">
                                        <thead>
                                            {headerGroups.map((headerGroup: any) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column: any) => (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.className}>
                                                            <span className="tabletitle">{column.render("Header")}</span>
                                                            <span>
                                                                {column.isSorted ? (
                                                                    column.isSortedDesc ? 
                                                                    (<i className="fa fa-angle-down"></i>) 
                                                                    : 
                                                                    (<i className="fa fa-angle-up"></i>)
                                                                ) 
                                                                : 
                                                                ("")}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {page.map((row: any) => {
                                                prepareRow(row);
                                                return (
                                                    <tr className="text-center" {...row.getRowProps()}>
                                                        {row.cells.map((cell: any) => {
                                                            return (
                                                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="d-block d-sm-flex mt-4 ">
                                        <span className="">
                                            Página{" "}
                                            <strong>
                                            {pageIndex + 1} de {pageOptions.length}
                                            </strong>{" "}
                                        </span>
                                        <span className="ms-sm-auto ">
                                            <button
                                                type='button'
                                                className="btn btn-defaul tablebutton me-2 d-sm-inline d-block my-1"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            >
                                                {" Anterior "}
                                            </button>
                                            <button
                                                type='button'
                                                className="btn btn-default tablebutton me-2 my-1"
                                                onClick={() => {
                                                    previousPage();
                                                }}
                                                disabled={!canPreviousPage}
                                            >
                                                {" << "}
                                            </button>
                                            <button
                                                type='button'
                                                className="btn btn-default tablebutton me-2 my-1"
                                                onClick={() => {
                                                    previousPage();
                                                }}
                                                disabled={!canPreviousPage}
                                            >
                                                {" < "}
                                            </button>
                                            <button
                                                type='button'
                                                className="btn btn-default tablebutton me-2 my-1"
                                                onClick={() => {
                                                    nextPage();
                                                }}
                                                disabled={!canNextPage}
                                            >
                                                {" > "}
                                            </button>
                                            <button
                                                type='button'
                                                className="btn btn-default tablebutton me-2 my-1"
                                                onClick={() => {
                                                    nextPage();
                                                }}
                                                disabled={!canNextPage}
                                            >
                                                {" >> "}
                                            </button>
                                            <button
                                                type='button'
                                                className="btn btn-default tablebutton me-2 d-sm-inline d-block my-1"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            >
                                                {" Siguiente "}
                                            </button>
                                        </span>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default ClientsTable;

import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';
import Checkbox from '../../Form/Checkbox';

import { requireField, errorTypes, auth } from '../../../utils/formValidations';

function OperatorUpdateForm (props: any) {
    const { onSubmit, user } = props;
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: props.operator
    });

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input 
                type={"text"} 
                name={"name"} 
                validation={{ required: requireField }} 
                label={"Nombre completo"} 
                placeholder="Ingrese nombre completo" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese un nombre completo' }]} 
                register={register} 
                errors={errors} 
            />
            <Input 
                type={"text"} 
                name={"department"} 
                validation={{ required: requireField }} 
                label={"Departamento"} 
                placeholder="Departamento" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese un departamento' }]}  
                register={register} 
                errors={errors} 
            />
            <Input 
                type={"text"} 
                name={"job"} 
                validation={{ required: requireField }} 
                label={"Posición"} 
                placeholder="Posición" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese su posición' }]}   
                register={register} 
                errors={errors} 
            />
            <Input 
                type={"text"} 
                name={"phone_number"} 
                validation={{ required: requireField, pattern: auth.phoneFormat }} 
                label={"Número de teléfono"} 
                placeholder="Número de teléfono" 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese un número de teléfono' }, { type: errorTypes.pattern, message: 'Número de teléfono inválido' }]}  
                register={register} 
                errors={errors} 
            />
            {
                user.userGroup === 'Operator'?
                    null
                :
                    <Checkbox type={"checkbox"} name={"disabled"} label={"Desactivar usuario"} errorMessage={"Seleccione el checkbox"} register={register} errors={errors} />
            }
            <button type="submit" className="btn btn-primary btn-block btn">Modificar</button>
        </form>
    );
};

export default OperatorUpdateForm;

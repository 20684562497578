import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';

import { requireField, errorTypes } from '../../../utils/formValidations';
import Select from '../../Form/Select';
import Checkbox from '../../Form/Checkbox';

function QuotationCreateForm(props: any) {
    const { quotations, addQuotation, onSubmit } = props;
    const { register, handleSubmit, reset, formState: { errors }, getValues, setValue, watch } = useForm();
    const extraMaterialReception = watch("extra_material_reception");
    const extraDelivery = watch("extra_delivery")

    function handleIncomingSubmit(formValues: any) {
        addQuotation(formValues);
        reset();
        setValue("extra_material_reception", false);
        setValue("extra_delivery", false);
    };

    const Form = () => {
        return (
            <form className="form" onSubmit={handleSubmit(handleIncomingSubmit)}>
               

                <Select
                    name={"quotation_type"}
                    options={["quick_time", "plus_time", "normal_time"]}
                    label={"Tipo de Cotizacion (Quick, Plus, Normal)"}
                    validation={{ required: requireField }}
                    errorMessages={[{ type: errorTypes.required, message: "Seleccionar Tipo" }]}
                    register={register}
                    errors={errors}
                />

                <Input
                    type={"datetime-local"}
                    name={"material_reception_date"}
                    validation={{
                        required: false,
                        min: new Date().toISOString(),
                        validate: function (value: any) { return new Date(value).getTime() < new Date(getValues("machine_scheduled_date")).getTime() },
                    }}
                    label={"Fecha de recepcion de material"}
                    placeholder="Fecha de recepcion de material"
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese una fecha' }, { type: errorTypes.min, message: 'Ingrese una fecha posterior a la actual' }, { type: errorTypes.validate, message: 'No puedes ingresar una fecha posterior a la fecha que se agendó el corte' }]}
                    register={register}
                    errors={errors}
                />


                <div className="card border rounded extra-container">
                    <div className="card-body">
                    <Checkbox
                        type={"checkbox"}
                        name={"extra_material_reception"}
                        label={"Active si desea agregar extra de recoger material en domicilio del cliente"}
                        errorMessage={"Seleccione el checkbox"}
                        register={register}
                        errors={errors}
                    />
                    {
                            extraMaterialReception && (
                                <Input
                                    type={"number"}
                                    name={"extra_material_reception_price"}
                                    validation={{ required: requireField, min: 50 }}
                                    label={"Ingrese el precio por el extra"}
                                    placeholder="Ingrese el precio del extra"
                                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese un precio o deshabilite el extra' }, { type: errorTypes.min, message: 'Ingrese una suma arriba de 50' }]}
                                    register={register}
                                    errors={errors}
                                />
                            )
                    }
                    </div>
                </div>

                <Input
                    type={"datetime-local"}
                    name={"machine_scheduled_date"}
                    validation={{ required: requireField, min: new Date().toISOString() }}
                    label={"Agendar inicio de máquina"}
                    placeholder="Fecha de inicio de máquina"
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese una fecha para agendar máquina' }, { type: errorTypes.min, message: 'Ingrese una fecha posterior a la actual' }]}
                    register={register}
                    errors={errors}
                />

                <Input
                    type={"number"}
                    name={"machine_duration_minutes"}
                    validation={{ required: requireField, min: new Date().toISOString() }}
                    label={"Agendar duracion de máquina (MINUTOS)"}
                    placeholder="Duracion de uso máquina en minutos"
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese una fecha para agendar máquina' }, { type: errorTypes.min, message: 'Ingrese una fecha posterior a la actual' }]}
                    register={register}
                    errors={errors}
                />

                <Input
                    type={"datetime-local"}
                    name={"due_date"}
                    validation={{
                        required: requireField,
                        min: new Date().toISOString(),
                        validate: function (value: any) { return new Date(value).getTime() > new Date(getValues("machine_scheduled_date")).getTime() },
                    }}
                    label={"Fecha de entrega"}
                    placeholder="Fecha de entrega"
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese una fecha' }, { type: errorTypes.min, message: 'Ingrese una fecha posterior a la actual' }, { type: errorTypes.validate, message: 'Ingrese una fecha posterior a la fecha agendada' }]}
                    register={register}
                    errors={errors}
                />

                <div className="card border rounded extra-container">
                    <div className="card-body">
                    <Checkbox
                        type={"checkbox"}
                        name={"extra_delivery"}
                        label={"Active si desea agregar extra de entregar corte en domicilio del cliente"}
                        errorMessage={"Seleccione el checkbox"}
                        register={register}
                        errors={errors}
                    />
                    {
                            extraDelivery &&(
                                <Input
                                    type={"number"}
                                    name={"extra_delivery_price"}
                                    validation={{ required: requireField, min: 50 }}
                                    label={"Ingrese el precio del extra"}
                                    placeholder="Ingrese el precio del extra"
                                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese un precio o deshabilite el extra' }, { type: errorTypes.min, message: 'Ingrese una suma arriba de 50' }]}
                                    register={register}
                                    errors={errors}
                                />
                            )
                    }
                    </div>
                </div>

                <Input
                    type={"number"}
                    name={"price"}
                    validation={{ required: requireField }}
                    label={"Ingresa el precio"}
                    placeholder="Ingresa el precio"
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese un precio' }, { type: errorTypes.pattern, message: 'Ingresa un precio' }]}
                    register={register}
                    errors={errors}
                />
                <button type="submit" className="btn btn-primary btn-block btn">Agregar</button>
            </form>
        );
    };

    return (
        <Fragment>
            {
                quotations.length === 3 ?
                    <h4 className="mt-5">¡Listo! Ya estan las 3 cotizaciones es momento de Terminar</h4>
                :
                    <Form />
            }
            {
                quotations.length < 1 ?
                    null
                    :
                    <div className="d-flex justify-content-center m-5"><button className="btn btn-info px-5" onClick={function () { onSubmit() }}>Terminar</button></div>
            }
        </Fragment>
    );
};

export default QuotationCreateForm;

import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ClientsContainer from '../../../containers/Dashboard/Clients';
import ClientDetail from '../../../containers/Dashboard/Clients/ClientDetail';
import ClientCreate from '../../../containers/Dashboard/Clients/ClientCreate';
import ClientUpdate from '../../../containers/Dashboard/Clients/ClientUpdate';

function Clients () {

    return (
        <Routes>
            <Route path="/" element={<ClientsContainer />} />
            <Route path="/:id/detail" element={<ClientDetail />} />
            <Route path="/create" element={<ClientCreate />} />
            <Route path="/:id/update" element={<ClientUpdate />} />
        </Routes>
    );
};

export default Clients;

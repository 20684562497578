import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import cognito from '../config/cognito';

// Create UserPool object to authenticate the AWS process
function createUserPool () : CognitoUserPool{
  let userPool: CognitoUserPool = new CognitoUserPool({
    UserPoolId: cognito.userPoolId,
    ClientId: cognito.clientId,
  });

  return userPool;
};

export function cognitoSignUp (values: any) {
  let userPool: CognitoUserPool = createUserPool();
  userPool.signUp(values.email, values.password, values, [], (err, result) => {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    }
    return result;
  });
};

export async function cognitoSignIn (values: any) {

  let authenticationData = {
    Username: values.email,
    Password: values.password
  };
  let authenticationDetails: AuthenticationDetails = new AuthenticationDetails(authenticationData);
  let userPool: CognitoUserPool = createUserPool();

  let userData = {
    Username: values.email,
    Pool: userPool
  };

  let cognitoUser: CognitoUser = new CognitoUser(userData);

  return new Promise(function (resolve, reject) {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(result) {

        const user = {
          id: result.getIdToken().decodePayload()["cognito:username"],
          accessToken: result.getIdToken().getJwtToken(),
          email: values.email,
          userGroup: result.getAccessToken().decodePayload()["cognito:groups"] ? result.getAccessToken().decodePayload()["cognito:groups"][0] : "Operator",
          userConfirmed: true,
          isSigned: true,
          passwordChallenge: false,
        };

        resolve(user);
      },
  
      newPasswordRequired: function(userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.
        // the api doesn't accept those field back
        delete userAttributes.email_verified;
        delete userAttributes.email;
  
        cognitoUser.completeNewPasswordChallenge(authenticationData.Password, userAttributes, this);
      },
    
      onFailure: function(err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
      },
    });
  });
};

export async function cognitoChangePassword (values: any) {
  
  let userPool: CognitoUserPool = createUserPool();
  let currentUser = userPool.getCurrentUser();

  return new Promise (function (resolve, reject) {
    
    if (currentUser) {
      currentUser.getSession(function(err: any, session: any) {
        
        if (err) {
          reject({ message: "Error getting session:'" });
        
        } else if (!session.isValid()) {
          reject({ message: "User is not fully authenticated" });
        
        } else {
          const accessToken = session.getAccessToken().getJwtToken();
          const idToken = session.getIdToken().getJwtToken();
    
          currentUser?.changePassword(values.password, values.newPassword, function(err, result) {
            
            if (err) {
              reject({ message: "Error changing password" });
            
            } else { 
              resolve({ message: "Password successfully changed" });

            }
          }, { accessToken, idToken });
        }
      });

    } else {
      reject({ message: "No user signed in" });
    }
  });
};

export async function cognitoNewPassword (values: any) {
  return new Promise (function (resolve, reject) {

    let userPool: CognitoUserPool = createUserPool();

    let userData = {
      Username: values.email,
      Pool: userPool
    };

    let cognitoUser: CognitoUser = new CognitoUser(userData);

    const sessionUserAttributes: Object = {
      family_name: values.family_name,
      name: values.name,
    }

    cognitoUser.completeNewPasswordChallenge(values.newPassword, sessionUserAttributes, {
      onSuccess: function(result) {
       
        const user = {
          accessToken: result.getAccessToken().getJwtToken(),
          email: values.email,
          userConfirmed: true,
          isSigned: true,
          passwordChallenge: false,
        };

        resolve(user);
      },
    
      onFailure: function(err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
      },
    });
  });
};

export async function cognitoSendForgotPasswordCode (email: any) {
  
  let userPool: CognitoUserPool = createUserPool();
  let userData = {
    Username: email,
    Pool: userPool
  };

  let cognitoUser: CognitoUser = new CognitoUser(userData);

  return new Promise (function (resolve, reject) {
    cognitoUser.forgotPassword({
      onSuccess: function(data) {
        // successfully initiated reset password request
        const user = {
          email: email,
          userConfirmed: false,
          isSigned: false,
        };
        resolve(user);
      },
      onFailure: function(err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
      },
    });
  });
};

export async function cognitoForgotPassword (values: any) {
  
  let userPool: CognitoUserPool = createUserPool();
  let userData = {
    Username: values.email,
    Pool: userPool
  };

  let cognitoUser: CognitoUser = new CognitoUser(userData);

  return new Promise (function (resolve, reject) {
    cognitoUser.confirmPassword(values.verificationCode, values.newPassword, {
      onSuccess() {
        resolve({ message: 'Password confirmed!' });
      },
      onFailure(err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
      },
    });
  });
};


export async function cognitoSignOut () {
  
  let userPool: CognitoUserPool = createUserPool();
  let cognitoUser = userPool.getCurrentUser();

  return new Promise (function (resolve, reject) {
    cognitoUser?.signOut();
    resolve({ message: "Succesfully sign out" });
  });
};

import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';

import { requireField, errorTypes, auth } from '../../../utils/formValidations';

function LoginForm(props: any) {

    const { onSubmit } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input
                type={"text"}
                name={"email"}
                validation={{ required: requireField, pattern: auth.emailFormat }}
                label={"Email"}
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese email' }, { type: errorTypes.pattern, message: 'Formato de email inválido' }]}
                register={register}
                errors={errors}
                placeholder={"Ingrese Email"}
            />
            <Input
                type={"password"}
                name={"password"}
                validation={{ required: requireField }}
                label={"Password"}
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese contraseña' }]}
                register={register}
                errors={errors}
                placeholder={"Ingrese Password"} />

            <button
                type="submit"
                className="btn btn-primary btn-block btn"
            >
                Inicia sesión
            </button>
            {/* Special links */}
            <div className="mt-4 d-flex text-center justify-content-center mb-2">
                <Link
                    to="https://www.facebook.com/"
                    target="_blank"
                    className="btn btn-icon btn-facebook me-3"
                    type="button"
                >
                    <span className="btn-inner--icon">
                        {" "}
                        <i className="bx bxl-facebook tx-18 tx-prime"></i>{" "}
                    </span>
                </Link>
                <Link
                    to="https://www.twitter.com/"
                    target="_blank"
                    className="btn btn-icon me-3"
                    type="button"
                >
                    <span className="btn-inner--icon">
                        {" "}
                        <i className="bx bxl-twitter tx-18 tx-prime"></i>{" "}
                    </span>
                </Link>
                <Link
                    to="https://www.linkedin.com/"
                    target="_blank"
                    className="btn btn-icon me-3"
                    type="button"
                >
                    <span className="btn-inner--icon">
                        {" "}
                        <i className="bx bxl-linkedin tx-18 tx-prime"></i>{" "}
                    </span>
                </Link>
                <Link
                    to="https://www.instagram.com/"
                    target="_blank"
                    className="btn  btn-icon me-3"
                    type="button"
                >
                    <span className="btn-inner--icon">
                        {" "}
                        <i className="bx bxl-instagram tx-18 tx-prime"></i>{" "}
                    </span>
                </Link>
            </div>
            <div className="main-signin-footer text-center mt-3">
                <p><Link to={"/forgot-password-send-code"} className="mb-3">¿Olvidaste tu contraseña?</Link></p>
            </div>
        </form>
    );
};

export default LoginForm;

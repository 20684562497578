import React from 'react';
import { Modal } from 'react-bootstrap';

function Error (props: any) {

    const { title, content, status, onDismiss } = props;

    return (
        <Modal show={status === "failed" ? true : false} aria-labelledby="contained-modal-title-vcenter" centered>
        <div className='modal-header'>
          <button className="btn btn-close" onClick={function () { onDismiss() }}>
            x
          </button>
        </div>
        <div className='modal-body'>
          <div className="tx-center">
            {" "}
            <i className="icon icon ion-ios-close-circle-outline tx-100 tx-danger lh-1 mg-t-20 d-inline-block"></i>{" "}
            <h4 className="tx-danger mg-b-20">
              {title}
            </h4>{" "}
            <p className="mg-b-20 mg-x-20">
              {content}
            </p>
            <button
              className="btn ripple btn-danger pd-x-25"
              onClick={function () { onDismiss() }}
              type="button"
            >
              Cerrar
            </button>{" "}
          </div>
        </div>
      </Modal>
    );
};

export default Error;


import React from 'react';
import { Routes, Route } from 'react-router-dom';

import OperatorsContainer from '../../../containers/Dashboard/Operators';
import OperatorDetail from '../../../containers/Dashboard/Operators/OperatorDetail';
import Profile from '../Profile';
import OperatorCreate from '../../../containers/Dashboard/Operators/OperatorCreate';
import OperatorUpdate from '../../../containers/Dashboard/Operators/OperatorUpdate';

function Operators () {

    return (
        <Routes>
            <Route path="/" element={<OperatorsContainer />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/:id/detail" element={<OperatorDetail />} />
            <Route path="/create" element={<OperatorCreate />} />
            <Route path="/:id/update" element={<OperatorUpdate />} />
        </Routes>
    );
};

export default Operators;
